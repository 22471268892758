import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, IconButton, InputAdornment,
    InputLabel,
    Select, Switch,
    TextField, Typography
} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, {useEffect, useRef, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import useMachineStore from "../../Store/MachineStore";
import Box from "@mui/material/Box";
import {previewFile, traitementFile} from "../../Service/TaskService";
import {red} from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import {Add, ChevronLeft, ChevronRight, Remove, Save} from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Fab from "@mui/material/Fab";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import OutlinedInput from "@mui/material/OutlinedInput";

export default function TaskConfiguration(
    {
        openTaskConfiguration,
        setOpenTaskConfiguration,
        files,
        machine,
        onsubmit
    }
) {
    const fileConfiguration = {
        numberPrint: 1,
        printingTechnique: "DTF",
        typeDrop: "newFile"
    }

    const {machines} = useMachineStore()
    const imageRef = useRef()
    const widthInputRef = useRef()
    const heightInputRef = useRef()
    const machineRef = useRef()
    const numberPrintRef = useRef()
    const [imageUrl, setImageUrl] = useState(null)
    const [onSave] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(null)
    const [filesData, setFilesData] = useState(new Array(files.length).fill({
        theFile: null,
        typeDrop: "newFile",
        printingTechnique: "DTF",
        machineId: 0,
        numberPrint: 1,
        pngConvert: true,
        previewUrl: null
    }))

    const [imageDimension, setImageDimension] = useState({
        width: 0,
        height: 0,
        ratio: 0
    })

    /*useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown, true)
        }
    }, [])*/

    const handleKeyDown = (event) => {
        switch (event.key) {
            case "ArrowRight":
                handleNext()
                break;

            case "ArrowLeft":
                handlePrev()
                break
        }
    }

    useEffect(() => {
        if (files.length) {
            setCurrentIndex(0)
        }
    }, [files]);

    useEffect(() => {
        if (files.length && currentIndex !== null) {
            const file = files[currentIndex]

            if (machine && numberPrintRef.current) {
                numberPrintRef.current.value = filesData[currentIndex].numberPrint ?? 1
                if (filesData[currentIndex].machineId) {
                    machineRef.current.value = filesData[currentIndex].machineId
                }
            }

            const _imageUrl = URL.createObjectURL(file);
            setImageUrl(_imageUrl)
            filesData[currentIndex] = {
                ...filesData[currentIndex],
                theFile: file,
                printerNumber: machineRef.current?.value,
                numberPrint: +numberPrintRef.current?.value
            }
            setFilesData(filesData)

            if (file.type.includes("image/")) {
                const image = new Image()
                image.onload = function () {
                    const w = (this.width / (118.11)).toFixed(2)
                    const h = (this.height / (118.11)).toFixed(2)
                    setImageDimension({
                        width: w,
                        height: h,
                        ratio: w / h
                    })
                }
                image.src = _imageUrl
                filesData[currentIndex] = {
                    ...filesData[currentIndex],
                    previewUrl: _imageUrl
                }
                setFilesData(filesData)
            }

            if (file.type.includes("application/illustrator") || file.type.includes("application/postscript")) {
                const image = new Image()
                image.onload = function () {
                    const w = (this.width / (118.11)).toFixed(2)
                    const h = (this.height / (118.11)).toFixed(2)
                    setImageDimension({
                        width: w,
                        height: h,
                        ratio: w / h
                    })
                }
                if (!filesData[currentIndex].previewUrl) {
                    previewFile(file).then(response => {
                        if (response.data) {
                            const previewUrl = response.data.previewUrl

                            setImageUrl(previewUrl)
                            image.src = previewUrl
                            filesData[currentIndex] = {
                                ...filesData[currentIndex],
                                previewUrl: previewUrl
                            }
                            setFilesData(filesData)
                        }
                    })
                } else {
                    image.src = filesData[currentIndex].previewUrl
                }

            }
        }
    }, [files, currentIndex])

    useEffect(() => {
        if (imageDimension.width && widthInputRef.current) {
            widthInputRef.current.value = imageDimension.width
            heightInputRef.current.value = imageDimension.height

            filesData[currentIndex] = {
                ...filesData[currentIndex],
                width: imageDimension.width,
                height: imageDimension.height
            }
            setFilesData(filesData)
        }
    }, [imageDimension.width, imageDimension.height])

    const handleNext = () => {
        if ((currentIndex + 1) < files.length) {
            setCurrentIndex(currentIndex + 1)
        }
    }

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1)
        }
    }

    const handleClose = () => {
        setOpenTaskConfiguration(false)
    }

    return (
        <>
            <Dialog
                open={openTaskConfiguration}
                onClose={handleClose}
                fullWidth
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        onsubmit(filesData)
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{display: "flex", alignItems: "center", gap: 1}}>
                    Configuration de taille de fichier
                </DialogTitle>
                <DialogContent>
                    {machine &&
                        <Stack direction="row" sx={{mt: 1}} gap={2}>
                            <FormControl
                                fullWidth
                                size="small"
                            >
                                <InputLabel>Imprimante</InputLabel>
                                <Select
                                    name="machineId"
                                    label="Imprimante"
                                    size="small"
                                    inputRef={machineRef}
                                    defaultValue={machine.number}
                                    onChange={() => {
                                        filesData[currentIndex] = {
                                            ...filesData[currentIndex],
                                            printerNumber: machineRef.current.value,
                                        }
                                    }}
                                >
                                    <MenuItem></MenuItem>
                                    {machines.filter(m => m.power).map(m => (
                                        <MenuItem key={m.id} value={m.number}>{m.number} - {m.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    }

                    <Grid2 container alignItems={"center"}>
                        {files.length > 1 &&
                            <Fab
                                size="large"
                                sx={{boxShadow: "none", position: "absolute"}}
                                onClick={handlePrev}
                            >
                                <ChevronLeft fontSize="large"/>
                            </Fab>
                        }
                        <Grid2 xs>
                            <Stack sx={{height: 520}}>
                                {files[currentIndex] && !files[currentIndex].type.includes("image/") && !files[currentIndex].type.includes("application/pdf") &&
                                    <div style={{textAlign: "center"}}>
                                        <Box sx={{display: "block", mb: 2}}>
                                            <FormControlLabel
                                                control={<Switch defaultChecked/>}
                                                onChange={(e) => {
                                                    filesData[currentIndex] = {
                                                        ...filesData[currentIndex],
                                                        pngConvert: e.target.checked
                                                    }
                                                    setFilesData(filesData)
                                                }}
                                                label="Convertir en PNG"/>
                                        </Box>
                                    </div>
                                }
                                {files[currentIndex] &&
                                    <Box sx={{
                                        flexGrow: 1,
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        {files[currentIndex].type.includes("application/pdf") &&
                                            <embed wmode="transparent"
                                                   src={imageUrl + "#toolbar=0&navpanes=0&scrollbar=0&wmode=transparent"}
                                                   style={{height: "100%"}} border="0" type="application/pdf"/>
                                        }

                                        {!files[currentIndex].type.includes("application/pdf") && filesData[currentIndex] &&
                                            <div style={{
                                                display: "inline-block",
                                            }}>
                                                {filesData[currentIndex] && !filesData[currentIndex].previewUrl &&
                                                    <CircularProgress/>
                                                }

                                                {filesData[currentIndex].previewUrl && filesData[currentIndex].previewUrl.length &&
                                                    <div style={{
                                                        backgroundImage: "url(\"/media/images/damier_front.jpg\")",
                                                        backgroundSize: "cover"
                                                    }}>
                                                        <img src={filesData[currentIndex].previewUrl} ref={imageRef}
                                                             style={{maxWidth: 300, maxHeight: 300}}/>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </Box>
                                }

                                <Paper sx={{py: 2, px: 20}}>
                                    <Typography textAlign={"center"} fontWeight={"bold"}>Taille impression
                                        souhaitée</Typography>
                                    <Stack direction="row" mt={3} gap={2}>
                                        <TextField
                                            fullWidth
                                            inputRef={widthInputRef}
                                            label="Largeur"
                                            size="small"
                                            name="width"
                                            InputProps={{
                                                startAdornment: <InputAdornment
                                                    position="start">cm</InputAdornment>,
                                            }}

                                            onChange={() => {
                                                const value = widthInputRef.current.value
                                                if (isNaN(value)) {
                                                    return
                                                }

                                                const width = +value
                                                heightInputRef.current.value = (width / imageDimension.ratio).toFixed(2)
                                            }}

                                            onBlur={() => {
                                                setImageDimension({
                                                    ...imageDimension,
                                                    width: +widthInputRef.current.value,
                                                    height: +heightInputRef.current.value
                                                })
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            inputRef={heightInputRef}
                                            label="Hauteur"
                                            size="small"
                                            name="height"
                                            InputProps={{
                                                startAdornment: <InputAdornment
                                                    position="start">cm</InputAdornment>,
                                            }}

                                            onChange={() => {
                                                const value = heightInputRef.current.value
                                                if (isNaN(value)) {
                                                    return
                                                }

                                                const height = +value
                                                widthInputRef.current.value = (height * imageDimension.ratio).toFixed(2)
                                            }}

                                            onBlur={() => {
                                                setImageDimension({
                                                    ...imageDimension,
                                                    width: +widthInputRef.current.value,
                                                    height: +heightInputRef.current.value
                                                })
                                            }}
                                        />
                                    </Stack>

                                    <Stack alignItems={"center"}>
                                        <FormControl sx={{width: 'auto', mt: 2, textAlign: "center"}} variant="outlined"
                                                     size="small">
                                            <InputLabel sx={{textAlign: "center"}} size="small"
                                                        htmlFor="outlined-adornment-password">Quantité</InputLabel>
                                            <OutlinedInput
                                                defaultValue={fileConfiguration.numberPrint}
                                                name="numberPrint"
                                                size="small"
                                                inputRef={numberPrintRef}
                                                onChange={(event) => {
                                                    filesData[currentIndex] = {
                                                        ...filesData[currentIndex],
                                                        numberPrint: numberPrintRef.current.value
                                                    }
                                                }}

                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <IconButton
                                                            aria-label="remove quantity"
                                                            edge="end"
                                                            tabIndex={-1}
                                                            onClick={() => {
                                                                if (+numberPrintRef.current.value > 1) {
                                                                    numberPrintRef.current.value = +numberPrintRef.current.value - 1
                                                                    filesData[currentIndex] = {
                                                                        ...filesData[currentIndex],
                                                                        numberPrint: numberPrintRef.current.value
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <Remove fontSize="small"/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Add quantity"
                                                            edge="end"
                                                            tabIndex={-1}
                                                            onClick={() => {
                                                                numberPrintRef.current.value = +numberPrintRef.current.value + 1
                                                                filesData[currentIndex] = {
                                                                    ...filesData[currentIndex],
                                                                    numberPrint: numberPrintRef.current.value
                                                                }
                                                            }}
                                                        >
                                                            <Add fontSize="small"/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Quantité"
                                            />
                                        </FormControl>
                                    </Stack>
                                </Paper>
                            </Stack>
                        </Grid2>
                        {files.length > 1 &&
                            <Fab
                                size="large"
                                onClick={handleNext}
                                sx={{boxShadow: "none", position: "absolute", right: 20}}
                            >
                                <ChevronRight fontSize={"large"}/>
                            </Fab>
                        }
                    </Grid2>
                </DialogContent>
                <DialogActions sx={{justifyContent: "center"}}>
                    <Button onClick={handleClose}>
                        Fermer
                    </Button>
                    <Button
                        type={"submit"}
                        disabled={onSave || currentIndex !== (files.length - 1)}
                        startIcon={onSave ? <CircularProgress
                            size={16}
                            sx={{color: red[500]}}
                        /> : <Save/>
                        }
                    >
                        Imprimer {files.length > 1 && <>({currentIndex + 1}/{files.length})</>}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}