import axios from "axios";
const BASE_URI = process.env.REACT_APP_BASE_URI;
const BASE_CONVERSION_URI = process.env.REACT_APP_BASE_CONVERSION_URI;

const headersAppJson = {
    headers: {
        "Content-Type": "application/json",
    },
};

const headersAppLd = {
    headers: {
        "Content-Type": "application/ld+json",
    },
};

export const imbricateImage = async (datas: object) => {
    const url = BASE_CONVERSION_URI + "/conversion/nested";
    const datasToSend = datas;

    try {
        const { data } = await axios.post(url, datasToSend, headersAppLd);
        console.log("Data: ", data);
        return { status: true, response: data.response, binHash:data.binHash };
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return { status: false, message: message, response: "error" };
    }
};

export const chat = async (datas: object) => {
    const url = BASE_URI + "/chat";
    const datasToSend = datas;

    try {
        const { data } = await axios.post(url, datasToSend, headersAppJson);
        console.log("Data: ", data);
        return { status: true, response: data };
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return { status: false, message: message, response: "error" };
    }
};
