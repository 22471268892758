import {Button, Chip, List} from "@mui/material";
import Grid from "@mui/material/Grid";
import React, {memo, useEffect, useState} from "react";
import {getTasks, removeFromHistory, updateTaskPosition} from "../Service/TaskService";
import socket from "../Service/Socket";
import ListItem from "@mui/material/ListItem";
import {TaskItem} from "./Machine/TaskItem";
import Box from "@mui/material/Box";
import {Archive} from "@mui/icons-material";
import {InView} from "react-intersection-observer";
import CircularProgress from "@mui/material/CircularProgress";

export const History = memo(({onPreviewFileTask, onPreviewTasks}) => {
    const [tasks, setTasks] = useState([])
    const [toDeleteTasks, setToDeletedTasks] = useState([])
    const [showLoading, setShowLoading] = useState(true)
    const io = socket

    const fetchHistories = () => {
        getTasks().then(result => {
            if (result.response === "success") {
                setShowLoading(result.fetchedTasks.length > 0 && result.fetchedTasks.length % 20 === 0)

                setTasks(prevTasks => {
                    const newTasks = result.fetchedTasks.filter(t => {
                        return !prevTasks.map(ts => ts.id).includes(t.id);
                    });

                    return [
                        ...newTasks,
                        ...prevTasks,
                    ];
                });
            }
        });
    }

    useEffect(() => {
        fetchHistories()

        io.on("updateMachine", (data) => {
            fetchHistories()
        })

        return () => {
            io.off("updateMachine");
        };
    }, []);

    return (
        <>
            <h1>
                <Button
                    onClick={() => {
                        onPreviewTasks(tasks)
                    }}
                >
                    <Chip icon={<Archive/>} label="HISTORIQUES" color="primary"/>
                </Button>
            </h1>
            <div className="scrollable-div" style={{height: "100%"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{px: 2}}>
                            <List dense={true}>
                                {(tasks ?? []).map((task, index) => (
                                    <ListItem
                                        key={index}
                                        disablePadding
                                    >
                                        <TaskItem
                                            task={task}
                                            onPreviewFileTask={onPreviewFileTask}
                                            showMachine={true}
                                            toDeleteTasks={toDeleteTasks}
                                            onTaskSended={(_machine, position) => {
                                                updateTaskPosition(task, position, _machine).then()
                                            }}
                                            onSetFirstPosition={() => {
                                                updateTaskPosition(task, "first", null).then(() => {
                                                })
                                            }}
                                            onRemoveTask={() => {
                                                const userConfirmed = window.confirm('Voulez-vous vraiment supprimer ?');
                                                if (userConfirmed === true) {
                                                    setToDeletedTasks([task.id])
                                                    removeFromHistory({
                                                        taskId: task.id,
                                                        status: 4,
                                                        folder: "history"
                                                    }).then()
                                                }
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Grid>
                </Grid>

                {showLoading &&
                    <InView onChange={(inView, entry) => {
                        if (inView) {
                            const nextPage = Math.ceil(tasks.length / 20) + 1
                            getTasks(nextPage).then(response => {
                                setTasks([
                                    ...tasks,
                                    ...response.fetchedTasks
                                ])
                            })
                        }
                    }}>
                        <Box sx={{textAlign: "center", mt: 2}}>
                            <CircularProgress/>
                        </Box>
                    </InView>
                }
            </div>
        </>
    )
}, (prevProps, nextProps) => {
    return true
})
