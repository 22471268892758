import {create} from 'zustand'

const useMachineStore = create((set) => ({
    machines: [],
    setMachines: (machines) => set((state) => ({machines: machines})),
    updateMachine: (updatedMachine) => set((state) => ({
        machines: state.machines.map((machine) => {
            return machine.id === updatedMachine.id ? {...machine, ...updatedMachine} : machine
        }),
    })),
}))

export default useMachineStore