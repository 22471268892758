import useMachineStore from "../../Store/MachineStore";
import React, {useCallback} from "react";
import IconButton from "@mui/material/IconButton";
import {FirstPage, Laptop, LastPage, MoreVert, Print, ScheduleSend} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {ListItemIcon, ListItemText} from "@mui/material";
import Utils from "../../Utils/Utils";
import Tooltip from "@mui/material/Tooltip";

export default function SendTaskButton({machine, onSendTask}) {
    const {machines} = useMachineStore()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleOpenMenu = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(null);
    };

    const getMachines = useCallback(() => {
        if (machine) {
            return machines.filter(m => m.power && (machine && machine.id !== m.id))
        } else {
            return machines.filter(m => m.power)
        }
    }, [machines, machine])

    return (
        <>
            <IconButton
                size={"small"}
                sx={{minWidth: 28}}
                aria-controls={openMenu ? 'long-menu' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleOpenMenu}
            >
                <MoreVert fontSize="small"/>
            </IconButton>

            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
            >
                {getMachines().map(_machine => (
                    <MenuItem sx={{pointerEvents: "none"}} key={_machine.id}>
                        <ListItemIcon sx={{pointerEvents: "all"}} onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}>
                            <Laptop fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText sx={{pointerEvents: "all"}} onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}>{Utils.formatMachineName(_machine)}</ListItemText>
                        <ListItemIcon sx={{position: "relative", ml: 2}}>
                            <Tooltip title="Imprimer en priorité">
                                <>
                                    <span style={{
                                        position: "absolute",
                                        left: 13.75,
                                        top: 1,
                                        width: 7,
                                        height: 7,
                                        borderRadius: "50%",
                                        background: "green"
                                    }}></span>
                                    <IconButton
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            onSendTask(_machine, "first")
                                            handleClose(e)
                                        }}
                                        sx={{pointerEvents: "all"}} size="small">
                                        <Print/>
                                    </IconButton>
                                </>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemIcon sx={{position: "relative"}}>
                            <Tooltip title="Imprimer à la fin">
                                <>
                                    <span style={{
                                        position: "absolute",
                                        left: 13.75,
                                        bottom: 1,
                                        width: 7,
                                        height: 7,
                                        borderRadius: "50%",
                                        background: "red"
                                    }}></span>
                                    <IconButton
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            onSendTask(_machine, "last")
                                            handleClose(e)
                                        }}
                                        sx={{pointerEvents: "all"}} size="small">
                                        <Print/>
                                    </IconButton>
                                </>
                            </Tooltip>
                        </ListItemIcon>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}