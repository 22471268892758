import moment from "moment";
import {v4 as uuid} from "uuid";

class Utils {
    emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    msgNull;

    constructor() {
        this.msgNull = "ne peut pas être vide";
    }

    verifyData(datas: any, typeVerif: any) {
        var verif;
        if (typeVerif === "printing") {
            verif = this.checkClenIfNull(datas, "name");
            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ nom " + this.msgNull,
                };
            }
        }

        if (typeVerif === "machine") {
            verif = this.checkClenIfNull(datas, "number");
            if (verif === "not_ok" && datas.id === null) {
                return {
                    type: "error",
                    message: "Le champ numéro " + this.msgNull,
                };
            }
        }

        if (typeVerif === "machine") {
            verif = this.checkClenIfNull(datas, "name");
            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ nom " + this.msgNull,
                };
            }
        }

        if (typeVerif === "machine") {
            verif = this.checkClenIfNull(datas, "printingTechnique");
            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ téchnique d'impression " + this.msgNull,
                };
            }
        }

        if (typeVerif === "machine") {
            verif = this.checkClenIfNull(datas, "defaultPrinting");
            if (verif === "ok" && datas.defaultPrinting === true) {
                verif = this.checkClenIfNull(datas, "innactivityTimePrinting");
                if (verif === "not_ok") {
                    return {
                        type: "error",
                        message: "Le champ temps d'innactivité pour l'impression " + this.msgNull,
                    };
                }

                verif = this.checkClenIfNull(datas, "defaultLocationFile");
                if (verif === "not_ok") {
                    return {
                        type: "error",
                        message: "Le champ chemin par défaut du fichier à imprimer " + this.msgNull,
                    };
                }
            }
        }

        /*if (typeVerif === "machine") {
            verif = this.checkClenIfNull(datas, "ipAdress");
            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ emplacement du fichier " + this.msgNull,
                };
            }
        }*/

        if (typeVerif === "recipe") {
            verif = this.checkClenIfNull(datas, "name");
            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ nom " + this.msgNull,
                };
            }
        }

        if (typeVerif === "recipe") {
            verif = this.checkClenIfNull(datas, "printingTechnique");
            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ téchnique d'impression " + this.msgNull,
                };
            }
        }
    }

    checkClenIfNull(objet: any, cle: any) {
        // Vérifie d'abord si la clé existe dans l'objet
        if (cle in objet) {
            // Ensuite, vérifie si la valeur associée à la clé n'est pas null
            if (objet[cle] !== null && objet[cle] !== "") {
                return "ok"; // La clé existe et n'est pas null
            }
        }
        return "not_ok"; // La clé n'existe pas ou est null
    }

    formatDate(value: any, outputFormat = "DD/MM/YYYY", fromFormat = "YYYY-MM-DD") {
        if (value) {
            const date = moment(value, fromFormat);
            value = date.format(outputFormat);
        }
        return value;
    }

    formatUUIDWithPrefix(prefix = null) {
        const id = uuid(); // Génère un UUID v4 unique
        if (prefix) {
            return prefix + id;
        } else {
            return id;
        }
    }

    SSEURI() {

        return "https://nesting.dtf-dtf.com/recipe";
    }

    formatMachineName(machine: { number: number, name: string }) {
        return `${machine.number} - ${machine.name}`
    }

    async getAllDroppedFiles(items: any) {
        const files: any[] = [];

        async function traverseFileTree(item: any) {
            return new Promise((resolve, reject) => {
                if (item.isFile) {
                    item.file((file: any) => {
                        files.push(file);
                        resolve('finish');
                    });
                } else if (item.isDirectory) {
                    const dirReader = item.createReader();
                    dirReader.readEntries(async (entries: any) => {
                        for (let entry of entries) {
                            await traverseFileTree(entry);
                        }
                        resolve('finish');
                    });
                }
            });
        }

        const traversePromises = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i].webkitGetAsEntry();
            if (item) {
                traversePromises.push(traverseFileTree(item));
            }
        }

        await Promise.all(traversePromises);
        return files.filter(file => {
            const fileType = file.type;
            return fileType === 'application/pdf' ||
                fileType === 'image/jpeg' ||  // for JPG and JPEG
                fileType === 'image/png' ||   // for PNG
                fileType === 'image/svg+xml' ||  // for SVG
                fileType === 'application/postscript' ||  // for EPS
                fileType === 'application/illustrator';  // for AI
        })
    }
}

export default new Utils();
