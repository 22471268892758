import {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {blue} from "@mui/material/colors";
import Utils from "../Utils/Utils";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import imagesRxjs from "../Service/imagesRxjs";

var allFiles = [];

/**
 * custom Hook
 * @returns {[boolean,{}]}
 */
const useManageImage = () => {
    const [index, setIndex] = useState(0);
    const [openDialogAddImage, setOpenDialogAddImage] = useState(false);
    const imagesInputRef = useRef(null);
    const [imageDimensions, setImageDimensions] = useState([]);
    const [activePreviewImageDatas, setActivePreviewImageDatas] = useState(null);
    var fileDatas = {};
    const [activeFileDatas, setActiveFileDatas] = useState(fileDatas);
    const [loadAddImage, setLoadAddImage] = useState(false);
    const [action, setAction] = useState("ADD");
    const [images, setImages] = useState([]);
    const [droppedFiles, setDroppedFiles] = useState([])

    useEffect(() => {
        //##Listen mission obj if it changed
        const imagesObservable = imagesRxjs.getImagesObservable();
        const subscriptionImages = imagesObservable.subscribe((datas) => {
            setImages(datas);
        });

        return () => {
            subscriptionImages.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (activeFileDatas.hasOwnProperty("ratio")) {
            var updatedImages = imageDimensions;
            updatedImages[index] = activeFileDatas;
            setImageDimensions(updatedImages);
            imagesRxjs.updateImage(activeFileDatas);
        }
    }, [activeFileDatas]);

    useEffect(() => {
        if (imageDimensions.length > 0 && activeFileDatas.hasOwnProperty("ratio")) {
            var currentImage = imageDimensions[index];
            setAction("ADD");
            const imageUrl = URL.createObjectURL(currentImage.theFile);
            setActivePreviewImageDatas(imageUrl);
            setActiveFileDatas(currentImage);
        }
    }, [index]);

    const onNext = () => {
        if (index < (imageDimensions.length - 1)) {
            setIndex(curr => {
                return curr + 1
            })
            return
        }
        // Mise à jour de l'état en ajoutant les nouvelles dimensions
        for (let i = 0; i < imageDimensions.length; i++) {
            const currentImage = imageDimensions[i];
            imagesRxjs.addImage(currentImage);
        }
        setImageDimensions([]);
        setIndex(0);
        handleCloseAddImage();
    }

    const dialogAddImageAction = <>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            {imageDimensions.length > 1 && index > 0 && action === "ADD" &&
                <Box>
                    <Button onClick={() => {
                        if (index > 0) {
                            setIndex(prev => {
                                var newIndex = prev - 1;
                                return newIndex;
                            })
                        }
                    }}>
                        Précédent
                    </Button>
                </Box>
            }

            {action === "ADD" && imageDimensions.length > 0 &&
                <Box sx={{m: 1, position: 'relative'}}>
                    <Button
                        variant="contained"
                        disabled={loadAddImage}
                        onClick={onNext}
                    >
                        {(index + 1) === imageDimensions.length ? "Valider" : "Suivant"}
                    </Button>
                    {loadAddImage && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: blue[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
            }

            {action === "EDIT" &&
                <Box>
                    <Button onClick={() => {
                        handleCloseAddImage();
                    }}>
                        Fermer
                    </Button>
                </Box>
            }

        </Box>
    </>;
    const handleUploadImage = async (event, dropped = false) => {
        event.preventDefault()
        event.stopPropagation()

        let _droppedFiles

        if (dropped) {
            allFiles = event.dataTransfer.files;
            _droppedFiles = await Utils.getAllDroppedFiles(allFiles)
        } else {
            allFiles = event.target.files;
            _droppedFiles = Array.from(allFiles)
        }

        setDroppedFiles(_droppedFiles)
    };

    const dialogContentAddImage = <div style={{minWidth: "300px", maxWidth: "580px"}}>
        <Grid container columns={12}>
            {action === "ADD" &&
                <Grid item xs={12} sm={12} lg={12} style={{marginTop: "10px", marginBottom: "20px"}}>
                    <div className="custom-upload" id="customUpload"
                         style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                         onDragOver={(e) => e.preventDefault()}
                         onDrop={(e) => handleUploadImage(e, true)}
                         onClick={(e) => handleButtonAddImageClick(e)}
                    >
                        <label htmlFor="uploadInput">
                            <IconButton color="primary" edge="end" aria-label="Reimprimer">
                                <CloudDownloadIcon/>
                            </IconButton> {" "} Sélectionner les images
                        </label>
                        <div className="preview" id="imagePreview">
                            <input ref={imagesInputRef}
                                   multiple
                                   type="file"
                                   style={{display: "none"}}
                                   onChange={handleUploadImage}
                            />
                        </div>
                    </div>
                </Grid>
            }


        </Grid>
    </div>;

    const handleOpenDialogAddImage = (currentAction = "ADD") => {
        if (currentAction === "ADD") {
            setActivePreviewImageDatas(null);
        }
        setOpenDialogAddImage(true);
    }

    const handleCloseAddImage = () => {
        setImageDimensions([]);
        setIndex(0);
        setOpenDialogAddImage(false);
    }

    const handleButtonAddImageClick = () => {
        // Clique sur l'élément input de type "file" lorsqu'on clique sur le bouton
        imagesInputRef.current.click();
    };

    return {
        imageDimensions,
        index,
        imagesInputRef,
        setImageDimensions,
        openDialogAddImage,
        setOpenDialogAddImage,
        setActiveFileDatas,
        setActivePreviewImageDatas,
        handleCloseAddImage,
        handleOpenDialogAddImage,
        setIndex,
        activeFileDatas,
        dialogContentAddImage,
        dialogAddImageAction,
        setAction,
        droppedFiles,
        setDroppedFiles
    }
}

export default useManageImage