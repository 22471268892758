import { Button, Container, Grid } from "@mui/material";
import Configuration from "./Configuration";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import HamburgerMenu from "./HamburgerMenu";
import React from "react";

const Imprimante = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/");
    };
    return (
        <>
            <HamburgerMenu />
            <Container maxWidth={"lg"}>

                <Grid container spacing={2} columns={12} alignItems={"center"}>
                    <Grid item xs={6}>
                        <h1 style={{ textAlign: "left" }}>
                            <SettingsIcon /> Configuration
                        </h1>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid
                            container
                            justifyContent={"flex-end"}
                            direction={"row"}
                            style={{ textAlign: "end" }}
                        >
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    onClick={handleClick}
                                >
                                    <ArrowBackIosIcon />
                                    Retour à liste des impressions
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div>
                    <div>
                        <Configuration />
                    </div>
                </div>
            </Container>
        </>

    );
};

export default Imprimante;
