const Constant = {
    TASK_STATUS_IN_PROGRESS: "2",
    TASK_STATUS_WAITING: "3",
    TASK_STATUS_FINISH: "1",
    TASK_STATUS_REMOVED: "4",
    TASK_STATUS_PRELOADED: "5",
    TASK_STATUS_PRINTING: "6",
}

export default Constant
