import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Typography from "@mui/material/Typography";
import Utils from "../../Utils/Utils";
import {Chip, Divider, Switch} from "@mui/material";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import HistoryIcon from "@mui/icons-material/History";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import Box from "@mui/material/Box";
import {ArrowDownward, ArrowUpward, Downloading, Print} from "@mui/icons-material";
import React, {useCallback, useState} from "react";
import {clearTasks, patchMachine} from "../../Service/MachineService";
import useMachineStore from "../../Store/MachineStore";
import {printFirstTask} from "../../Service/TaskService";
import Tooltip from "@mui/material/Tooltip";
import Constant from "../../Utils/Constant";
import moment from "moment";
import IconLoadingButton from "../UI/IconLoadingButton";

export default function MachineActions({machine, onScrolltTop, onScrollBottom}) {
    const {updateMachine} = useMachineStore()
    const [onPrintFirst, setOnPrintFirst] = useState(false)
    const [onClearQueue, setOnClearQueue] = useState(false)

    const handleChangeAutomaticPrinting = () => {
        const _machine = {
            ...machine,
            automaticPrinting: !machine.automaticPrinting
        }

        updateMachine(_machine)
        patchMachine(machine.id, {
            automaticPrinting: _machine.automaticPrinting
        }).then()
    }

    const handleOpenDialogTaskImprimante = () => {

    }

    const handleOpenDialogInnactivityTime = () => {

    }

    const handleRemoveQueueMachine = () => {
        if (window.confirm("Voulez-vous vraiment tout effacer ?")) {
            setOnClearQueue(true)
            setTimeout(() => {
                clearTasks(machine.id).then(() => {
                    setOnClearQueue(false)
                })
            }, 1000)
        }
    }

    const handleMoveWaitingQueueMachine = () => {

    }

    const isPreloading = useCallback(() => {
        if (machine.tasks.filter(m => m.status === Constant.TASK_STATUS_PRINTING).length > 0) {
            return false
        }

        if (machine.preload) {
            const preloadedTasks = machine.tasks.filter(m => m.status === Constant.TASK_STATUS_PRELOADED)
            if (preloadedTasks.length) {
                const task = preloadedTasks[0]
                const duration = getDuration(task.updatedAt)
                if (duration >= machine.preloadDuration) {
                    return false
                }
            }

            return true;
        }

        return false
    }, [machine])

    const hasPrintingFile = useCallback(() => {
        return machine.tasks.filter(m => m.status === Constant.TASK_STATUS_IN_PROGRESS).length > 0
    }, [machine])

    const getDuration = (date) => {
        const now = moment()
        const lastUpdate = moment(date)
        const diffDate = now.diff(lastUpdate)
        return moment.duration(diffDate).asSeconds()
    }

    return (
        <>
            <Grid2 container spacing={1} alignItems={"center"} justifyContent={"center"}>
                <Grid2 xs={5}>
                    <div style={{height: 60}}>
                        {machine.note &&
                            <>
                                <div className={"text-note"} style={{
                                    textAlign: "center", color: "orange", fontSize: "12px",
                                    marginBottom: "2px"
                                }}>
                                    <Tooltip title={machine.note}>
                                        <>
                                            {machine.note}
                                        </>
                                    </Tooltip>
                                </div>
                                <div>
                                    <Typography variant="caption" textAlign="center" gutterBottom>
                                        {Utils.formatDate(machine.updatedAt)}
                                    </Typography>
                                </div>
                            </>
                        }
                    </div>
                </Grid2>
                <Grid2 xs={"auto"}>
                    <Stack direction="row" alignItems={"center"} justifyContent={"start"}>
                        <IconLoadingButton
                            tooltipLabel="Effacer la file d'attente"
                            icon={<CleaningServicesIcon fontSize="small"/>}
                            onClick={handleRemoveQueueMachine}
                            loading={onClearQueue}
                        />
                    </Stack>
                </Grid2>
                <Grid2 xs={"auto"}>
                    <div>
                        <Typography
                            variant="body2"
                            color={machine.automaticPrinting ? "success.main" : "info.main"}
                            fontWeight={"bold"}
                        >
                            Auto
                        </Typography>
                        <Switch
                            disabled={!machine.power}
                            color="success"
                            size="medium"
                            checked={machine.automaticPrinting}
                            onChange={handleChangeAutomaticPrinting}
                        />
                    </div>
                </Grid2>
            </Grid2>

            <Stack direction="row">
                <Typography
                    variant="subtitle2" sx={{mt: 1}}
                    fontWeight="bold">
                    {machine.printingTechnique.name}
                </Typography>
                <Box sx={{ml: "auto"}}>
                    <IconButton
                        color="default"
                        onClick={onScrolltTop}
                        size="small"
                    >
                        <ArrowUpward fontSize="small"/>
                    </IconButton>
                    <IconButton
                        color="default"
                        onClick={onScrollBottom}
                        size="small"
                    >
                        <ArrowDownward fontSize="small"/>
                    </IconButton>

                    {onPrintFirst &&
                        <Typography variant="caption">
                            En cours ...
                        </Typography>
                    }
                    <IconButton
                        color="primary"
                        disabled={!(machine.tasks && machine.tasks.length) || !machine.power || hasPrintingFile()}
                        onClick={() => {
                            setOnPrintFirst(true)
                            printFirstTask(machine).finally(() => {
                                setOnPrintFirst(false)
                            })
                        }}
                        sx={{position: "relative"}}
                    >
                        {isPreloading() ?
                            <Tooltip title="Préchager le fichier">
                                <Downloading/>
                            </Tooltip>
                            :
                            <Tooltip title="Imprimer le fichier">
                                <Print/>
                            </Tooltip>
                        }
                    </IconButton>
                    <Typography
                        variant="caption"
                        fontWeight="bold">
                        ({machine.tasks ? machine.tasks.length : 0})
                    </Typography>
                </Box>
            </Stack>
            <Divider/>
        </>
    )
}