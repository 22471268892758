import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Delete, Laptop} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {formatTaskName} from "../../Service/TaskService";
import HistoryIcon from "@mui/icons-material/History";
import CircularProgress from "@mui/material/CircularProgress";
import {red} from "@mui/material/colors";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import SendTaskButton from "./SendTaskButton";
import Stack from "@mui/material/Stack";

export function TaskItem(
    {
        task,
        showMachine = false,
        toDeletedTasks = [],
        onSetFirstPosition,
        onPreviewFileTask,
        onTaskSended,
        onRemoveTask,
    }
) {

    return (
        <ListItemButton
            sx={{
                opacity: "1 !important",
                pl: showMachine ? 1 : 0,
                pr: showMachine ? 0.5 : 0
            }}
        >
            {showMachine &&
                <ListItemIcon
                    size="small"
                    sx={{minWidth: 28}}
                >
                    <Tooltip title={"Impression sur la Machine " + task.machine.number}>
                        <Stack direction="row" gap={1} sx={{pr: 1}}>
                            <Typography variant="subtitle2">{task.machine.number}</Typography>
                            <Laptop fontSize="small"/>
                        </Stack>
                    </Tooltip>
                </ListItemIcon>
            }

            <ListItemText
                primary={
                    <Typography
                        fontSize="small"
                        sx={{
                            display: "block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            paddingLeft: 1,
                            paddingRight: 1,
                        }}
                    >
                        {formatTaskName(task)}
                    </Typography>
                }
                onClick={() => {
                    onPreviewFileTask(task)
                }}
            />

            <ListItemIcon
                size="small"
                sx={{minWidth: 28}}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (onSetFirstPosition) {
                        onSetFirstPosition()
                    }
                }}
            >
                <Tooltip title={"Mettre en premier"}>
                    <HistoryIcon fontSize="small"/>
                </Tooltip>
            </ListItemIcon>

            <ListItemIcon
                disabled={toDeletedTasks.length > 0}
                size="small"
                sx={{minWidth: 28}}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onRemoveTask(task)
                }}
            >
                {toDeletedTasks.includes(task.id) &&
                    <CircularProgress
                        size={24}
                        sx={{color: red[500]}}
                    />
                }

                {!toDeletedTasks.includes(task.id) &&
                    <Tooltip title={"Supprimer"}>
                        <Delete fontSize="small" color="error"/>
                    </Tooltip>
                }
            </ListItemIcon>

            <SendTaskButton
                machine={task.machine}
                onSendTask={(_machine, position) => {
                    onTaskSended(task, _machine, position)
                }}
            />
        </ListItemButton>
    )
}
