import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {useCallback, useEffect, useState} from "react";

export default function TaskPreloadProgress({preloadDuration}) {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if ((progress * 100 / preloadDuration) < 100) {
                setProgress(Math.min(progress + 1))
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [progress, preloadDuration]);

    const getProgression = useCallback(() => {
        return (progress * 100 / preloadDuration)
    }, [progress, preloadDuration])

    return (
        <>
            <Box sx={{position: 'relative', display: 'inline-flex'}}>
                <CircularProgress color="success" variant="determinate" value={getProgression()} size={28}/>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        fontSize={8}
                        component="div"
                        color="text.secondary"
                    >{getProgression().toFixed(0)}%</Typography>
                </Box>
            </Box>
        </>
    )
}