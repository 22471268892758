import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function IconLoadingButton({tooltipLabel, icon, onClick, loading}) {
    return (
        <Box sx={{position: "relative"}}>
            <Tooltip title={tooltipLabel}>
                <IconButton onClick={onClick}>
                    {icon}
                </IconButton>
            </Tooltip>
            {loading &&
                <CircularProgress
                    size={36}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        pointerEvents: "none"
                    }}
                />
            }
        </Box>
    )
}
