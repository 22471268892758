import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Grid,
    LinearProgress, Switch, Typography
} from "@mui/material";
import {styled} from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import axios from "axios";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Stack from "@mui/material/Stack";
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {getHystories, getOneHystory} from "../Service/AssisstantService";

const BASE_CONVERSION_URI = process.env.REACT_APP_BASE_CONVERSION_URI;
const { v4: uuidv4 } = require('uuid');
var sentFiles = [];
const BASE_URI = process.env.REACT_APP_BASE_CONVERSION_URI;
var imgGen = false;
var currentChatId = null;

const CircularProgressWithLabel = (props) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography style={{color:"#FFF"}} variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

const OpenApi = ({}) => {

    const [messages, setMessages] = useState([
        { role: 'system', content: 'You are a helpful assistant.' }
    ]);
    const [userInput, setUserInput] = useState('');
    const [loadRes, setLoadRes] = useState(false);
    const chatInputRef = useRef();
    const chatContainerRef = useRef();
    const imagesInputRef = useRef(null);
    const [imageGeneration, setImageGeneration] = useState(false);
    const [originalFiles, setOriginalFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState([]);
    const [uploadImageProgress, setUploadImageProgress] = useState(0);
    const [histories, setHystories] = useState([]);

    useEffect(() => {
        getAllHystory();

    }, []);

    useEffect(() => {
        const handleKeyDown = (e) => {
            // Si la touche Entrée est pressée sans Shift et la largeur de la fenêtre est supérieure à 800 pixels, gérer le message sortant
            if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
            }
        };

        // Ajouter un écouteur d'événements sur le composant
        document.addEventListener("keydown", handleKeyDown);

        // Nettoyer l'écouteur d'événements lorsque le composant est démonté
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [userInput]);

    const getAllHystory = async () => {
        var { status, fetchedHistories } = await getHystories();
        if (status){
            setHystories(fetchedHistories);
        }
    };

    const handleOutgoingChat = () => {
        const userText = chatInputRef.current.value.trim(); // Obtenez la valeur de chatInput et supprimez les espaces supplémentaires
        if (!userText) return; // Si chatInput est vide, retournez d'ici

        // Effacez le champ de saisie et réinitialisez sa hauteur
        //chatInputRef.current.value = "";
        //chatInputRef.current.style.height = `${initialInputHeight}px`;

        const html = `<div className="chat-content">
                        <div className="chat-details">
                            <img src="/media/images/user.jpg" alt="user-img">
                            <p>${userText}</p>
                        </div>
                    </div>`;

        // Créez un élément de chat sortant avec le message de l'utilisateur et ajoutez-le au conteneur de chat
        // const outgoingChatDiv = createChatElement(html, "outgoing");
        /*chatContainerRef.current.querySelector(".default-text")?.remove();
        chatContainerRef.current.appendChild(outgoingChatDiv);
        chatContainerRef.current.scrollTo(0, chatContainerRef.current.scrollHeight);
        setTimeout(showTypingAnimation, 500);*/
    };

    const sendMessage = async () => {

        console.log("Type message: ", userInput);

        if (!userInput) return;

        var userMessage = { role: 'user', content: userInput.trim() };
        var newMessages = [...messages, userMessage];
        var action = "TEXT_GENERATION";

        action = "TEXT_GENERATION";
        setMessages(newMessages);

        var publicUrlFile = "";

        if (sentFiles.length > 0){
            var defaultFile = sentFiles[0];
            publicUrlFile = defaultFile.urlFile;
            action = "VISION";
        }

        if (imgGen){
            action = "IMAGE_GENERATION";
        }

        var datas = {
            currentChatId: currentChatId,
            action: action,
            messages: newMessages,
            text: userInput,
            url: publicUrlFile,
            imageGeneration: imgGen
        };

        setUserInput('');
        console.log("Datas: ", datas);
        console.log("Checked: ", imgGen);

        const url = BASE_URI + "/assisstant/chat";
        // const { response } = await chat(datas);
        try {

            setLoadRes(true);

            const { data } = await axios.post(url, datas, {
                headers: {
                    "Content-Type": "application/json",
                }
            });

            console.log("Data: ", data);
            /*const result = JSON.parse(data);
            console.log("Result: ", result);*/
            var assistantMessage;

            if (data.hasOwnProperty("error")){

                assistantMessage = { role: 'assistant', content: data.content };

            }else {

                currentChatId = data?.currentChatId;

                if (imgGen){

                    var msgDisplay = <div className="image-loader-container">
                                                    <div className={"del-top-right"}>
                                                        <FileDownloadIcon style={{cursor:"pointer"}} onClick={(e)=> handleSaveAs(e, data.content )} sx={{ fontSize: 30 }} />
                                                    </div>
                                                    <img src={data.content} alt="Uploaded" className="uploaded-image" />
                                            </div>;
                    assistantMessage = { role: 'assistant', content: msgDisplay };
                    setUploadImageProgress(0);
                }else {

                    assistantMessage = { role: 'assistant', content: data.content };
                }

                console.log("Assisstant msg: ", assistantMessage);
            }

            newMessages = [...newMessages, assistantMessage];

            console.log("New message: ", newMessages);
            setLoadRes(false);
            setMessages(newMessages);

            chatContainerRef.current.scrollTo(0, chatContainerRef.current.scrollHeight);

            // return { status: true, response: data };
        } catch (error) {
            const message =
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message;

            console.log("ERROR: ", message);
            // return { status: false, message: message, response: "error" };
        }

    };

    const copyResponse = (copyBtn) => {
        // Copy the text content of the response to the clipboard
        const reponseTextElement = copyBtn.parentElement.querySelector("p");
        navigator.clipboard.writeText(reponseTextElement.textContent);
        copyBtn.textContent = "done";
        setTimeout(() => copyBtn.textContent = "content_copy", 1000);
    }

    const traitementMessage = (msg, key)=>{
        var res = "";

        if (msg.role === "assistant"){
            // Display the typing animation and call the getChatResponse function
            res = <div className="chat incoming" key={key}>
                <div className="chat-content" >
                    <div className="chat-details">
                        <img className={"img-chat"} src="/media/images/chatbot.jpg" alt="chatbot-img" />
                        <div style={{marginLeft:"10px"}}><pre><code>{msg.content}</code></pre></div>
                    </div>
                    <span
                        onClick={(e)=> {

                        }}
                        style={{marginLeft: "10px", fontSize: "14px"}}
                        className="material-symbols-rounded">
                        <Stack direction={"row"}>
                            <div><ContentCopyIcon sx={{ fontSize: 20 }} /> </div>
                            <div>Copier</div>
                        </Stack>
                    </span>
                </div>
            </div>;
        }else if (msg.role === "user"){
            res = <div className="chat outgoing" key={key}>
                <div className="chat-content">
                    <div className="chat-details">
                        <img className={"img-chat"} src="/media/images/user.jpg" alt="user-img" />
                        <div style={{marginLeft:"10px"}}>{msg.content}</div>
                    </div>
                </div>
            </div>;
        }
        return res;
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const resetSearch = ()=>{
        setMessages([
            { role: 'system', content: 'You are a helpful assistant.' }
        ]);
        setImageGeneration(false);
        imgGen = false;
        sentFiles = [];
        currentChatId = null;
        setOriginalFiles([]);
    }

    const handleButtonAddImageClick = () => {
        // Clique sur l'élément input de type "file" lorsqu'on clique sur le bouton
        imagesInputRef.current.click();
    };

    const handleChangeGenerateImage = (event) => {
        var status = event.target.checked;

        console.log("Status Generate image: ", status);
        setImageGeneration(status);
        imgGen = status;
        if (status){
            setOriginalFiles([]);
            sentFiles = [];
        }
    };

    const LinearProgressWithLabel = (props) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    const formatUUIDWithPrefix = (prefix = null) => {
        const uuid = uuidv4(); // Génère un UUID v4 unique
        if (prefix){
            return prefix + uuid;
        }else {
            return uuid;
        }
    }

    const handleUploadFile = async (event)=> {

        var allFiles = event.target.files;

        var uploadedFiles = [];
        // Array.from pour convertir la liste des fichiers en tableau
        Array.from(allFiles).forEach((file, i) => {
            var item = {theFile: file, fileName: "", progress: 0 };
            uploadedFiles = [...uploadedFiles, item];
        });
        var uFiles = [...(originalFiles), ...uploadedFiles];
        setOriginalFiles(uFiles);

        // Reset progress state
        setUploadProgress(Array(uploadedFiles.length).fill(0));

        for (let i = 0; i < uploadedFiles.length; i++) {
            const file = uploadedFiles[i];
            // Create form data
            const formData = new FormData();
            const UUID = formatUUIDWithPrefix();
            formData.append("theFile", file["theFile"]);
            formData.append("uuid", UUID);

            // Send request to upload file
            try {
                const response = await axios.post(`${BASE_CONVERSION_URI}/conversion/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        // Update upload progress
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

                        setOriginalFiles(prevFiles => {

                            const newProgress = [...prevFiles];
                            var foundIndex = prevFiles.findIndex(function(obj) {
                                return obj.theFile.name === file.theFile.name;
                            });
                            if (foundIndex !== -1) {
                                newProgress[foundIndex].progress = progress;
                            }
                            return newProgress;

                        });

                    }
                });
                var result = response.data;
                var infosFile = {fileName: result.fileName, urlFile: result.urlFile, originalFileName: file.theFile.name};
                sentFiles = [...sentFiles, infosFile];

            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
            }
        }
    }

    const handleDeleteImage = (e, file, index)=>{

        const userConfirmed = window.confirm('Voulez-vous vraiment supprimer ce historique?');
        if (userConfirmed === true){
            var updatedFiles = originalFiles.filter((item) => {
                return item.theFile.name !== file.name;
            });

            var updatedSentFiles = sentFiles.filter((item)=> {
                return item.originalFileName !== file.name;
            });
            setOriginalFiles(updatedFiles);
            sentFiles = updatedSentFiles;
        }
    }

    const handleSaveAs = async (e, urlToTheFile)=> {

        // Créer un objet URL à partir du Blob

        /*const url = URL.createObjectURL(urlToTheFile);
        // Créer un élément de lien
        const link = document.createElement('a');
        // Définir l'URL du lien sur l'URL du Blob
        link.href = url;
        // Définir le nom de fichier lors du téléchargement
        link.download = file.fileName;
        // Ajouter le lien au DOM
        document.body.appendChild(link);
        // Simuler un clic sur le lien pour déclencher le téléchargement
        link.click();
        // Nettoyer
        URL.revokeObjectURL(url);
        document.body.removeChild(link);*/

        window.open(urlToTheFile, "_blank");

    }

    const deleteBtn = (file, index)=> {
        return <div className="del-top-right">
            <Button
                style={{minWidth:"40px"}}
                size={"small"}
                variant={"contained"}
                color={"error"} onClick={(e)=> handleDeleteImage(e, file, index)}>
                X
            </Button>
        </div>
    };

    const displaySelectedImage = (currentFile, index)=> {

        var file = currentFile.theFile;
        var fileName = file.name, res, type = file.type,
            typeSplited = type.split("/"),
            typeFirstPart = typeSplited[0];
        var fileNameSplited = fileName.split(".");
        var partName = fileNameSplited[0],
            extension = fileNameSplited[1];

        if (typeFirstPart !== "image"){
            res = <Grid item sm={12} lg={4} key={index} className="image-item" style={{position:"relative"}}>
                    <FilePresentIcon key={index} sx={{ fontSize: 40 }} />
                    <p className="image-name">
                        <span style={{fontSize: "0.75rem"}}>{file.name}</span>{" "}
                    </p>
                    {deleteBtn(file, index)}

                    <Box sx={{ width: '100%' }}>
                        <LinearProgressWithLabel value={currentFile.progress} />
                    </Box>
                </Grid>
        }else {
            res = <Grid item sm={12} lg={4} key={index}>
                    <div className="img-c">
                        <img src={URL.createObjectURL(file)} alt={`Image ${index}`}
                             style={{
                                 width: '100%',
                                 height: 'auto',
                                 display: 'block'}} className="image-preview" />

                        <p className="image-name">
                            <span style={{fontSize: "0.75rem", color:"#fff" }}>{file.name}</span>{" "}

                            {currentFile.progress === 100 && (
                                <span style={{color:"rgb(49, 162, 76)", fontFamily:"bolt!important"}}>ok <CheckIcon sx={{ fontSize: 15 }} /></span>
                            )}

                        </p>

                        {currentFile.progress < 100 && (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel value={currentFile.progress} />
                            </Box>
                        )}
                        {deleteBtn(file, index)}
                    </div>
                </Grid>
        }
        return res;

    }

    const handleClickHystory = async (history)=> {

        currentChatId = history["id"];
        var {status, fetchedHistory} = await getOneHystory(history["id"]);

        if (status){
            var historyMsg = [];
            var theHistory = fetchedHistory;

            console.log("The history: ", theHistory);
            historyMsg = theHistory["content"];

            console.log("Messages: ", historyMsg);
            setMessages(historyMsg);
        }
    }
    const displayHystory = (item, index)=> {

        var res = null;
        var contents = item["content"];
        if (contents.length > 2){
            var firstUserMessage = contents[1];
            res = <li key={index}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <div className={"text-elipsis"} onClick={(e)=> {
                        handleClickHystory(item);
                    }} >{firstUserMessage["content"]}</div>
                    <MoreHorizIcon sx={{ fontSize: 20 }} />
                </Stack>
            </li>;
        }

        return res;
    }

    return (

        <div>

            <Grid container columns={12}>
                <Grid item sm={12} xs={12} lg={2}>
                    <div style={{backgroundColor:"#333333", height:"100vh", padding:"4px"}}>
                        <Button style={{boderColor:"1px solid white", color:"white", textAlign:"start", marginBottom:"20px"}} onClick={(e)=> resetSearch()} fullWidth variant="outlined" startIcon={<AddIcon />}>
                            New chat
                        </Button>
                        <div className="announcement-list">
                            <ul>

                                {histories.map(
                                    (item, index) => (
                                        displayHystory(item, index)
                                    )
                                )}

                            </ul>
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12} xs={12} lg={10}>
                    <div className="container-chat">
                        <div ref={chatContainerRef} className="chat-container" style={{height:"100vh", display:"flex", flexDirection:"column"}}>

                            {messages.length === 1 &&
                                <div className="default-text">
                                    <h1>ChatGPT Open API</h1>
                                    <p>Démarrez une conversation et explorez la puissance de l'IA.<br/>
                                        Votre discussion sera affichée ici.</p>
                                </div> }
                            {messages.map((msg, index) => {
                                return traitementMessage(msg, index);
                            })}
                            {loadRes && <div className={"chat incoming"}>
                                <div className="chat-content">
                                    <div className="chat-details">
                                        <img className={"img-chat"} src="/media/images/chatbot.jpg" alt="chatbot-img"/>
                                        <div className="typing-animation">
                                            <div className="typing-dot" style={{ '--delay': '0.2s' }}></div>
                                            <div className="typing-dot" style={{ '--delay': '0.3s' }}></div>
                                            <div className="typing-dot" style={{ '--delay': '0.4s' }}></div>
                                        </div>
                                    </div>
                                    <span onClick="copyResponse(this)" className="material-symbols-rounded"></span>
                                </div>
                            </div>}

                            {uploadImageProgress > 0 &&
                                <div className="image-loader-container">
                                    <CircularProgressWithLabel value={uploadImageProgress} />
                                </div>
                            }

                        </div>
                        <div className="typing-container">

                            <Grid container direction="row" spacing={2}>
                                { originalFiles.map((item, index) => (
                                    displaySelectedImage(item, index)
                                ))}
                            </Grid>
                            <Grid container direction="column">
                                 <Grid item sm={12}>
                                     <div className="typing-content">
                                         <div className="typing-controls">
                                                <span id="upload-btn" style={{borderRadius: "4px 0 0 4px"}} className="material-symbols-rounded"
                                                      onClick= {(e) => handleButtonAddImageClick(e)}>
                                                    <VisuallyHiddenInput
                                                        ref={imagesInputRef}
                                                        type="file"
                                                        onChange={(e) => {
                                                            handleUploadFile(e);
                                                        }}
                                                    />
                                                    <AttachFileIcon/>
                                                </span>
                                         </div>
                                         <div className="typing-textarea">
                                            <textarea ref={chatInputRef}
                                                      id="chat-input"
                                                      value={userInput}
                                                      onChange={(e)=> {
                                                          const theValue = e.target.value;
                                                          setUserInput(theValue);
                                                      }}
                                                      spellCheck="false" placeholder="Entrez une invite ici" required>
                                            </textarea>

                                             <span id="send-btn" className="material-symbols-rounded" onClick={(e)=> {
                                                 sendMessage();
                                             }}
                                             ><SendIcon/></span>
                                         </div>
                                         <div className="typing-controls" style={{marginLeft: "7px"}}>
                                             <span id="delete-btn" className="material-symbols-rounded"><DeleteIcon/></span>
                                         </div>
                                     </div>
                                 </Grid>
                            </Grid>

                            <FormControlLabel
                                control={
                                    <Switch  checked={imageGeneration}
                                            onChange={handleChangeGenerateImage}
                                            name="generateImage" />
                                }
                                style={{color:"#fff"}}
                                label="Générer une image"
                            />

                        </div>
                    </div>
                </Grid>
            </Grid>
            
        </div>

    )
}

export default OpenApi