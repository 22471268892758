import React, {useEffect, useRef, useState} from 'react'
import {
    Button, Chip,
    Container,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    Select,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {styled} from "@mui/system";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {getHystories, getOneHystory } from "../Service/AssisstantService";
import axios from "axios";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import CheckIcon from "@mui/icons-material/Check";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import {listAssisstant, query} from "../Service/AssisstantService";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import AudioRecorderComponent from "./AudioRecorderComponent";
import UrlForm from "./Form/UrlForm";
import AddIcon from "@mui/icons-material/Add";
import Popup from "./Popup";
import {blue} from "@mui/material/colors";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";

const BASE_CONVERSION_URI = process.env.REACT_APP_BASE_CONVERSION_URI;
const { v4: uuidv4 } = require('uuid');
var sentFiles = [];

const BASE_URI = process.env.REACT_APP_BASE_CONVERSION_URI;
const headersAppJson = {
    headers: {
        "Content-Type": "application/json",
    },
};
var imgGen = false;
var currentChatId = null;


const CREATE_ASSISSTANT = "CREATE_ASSISSTANT";
const CREATE_THREAD = "CREATE_THREAD";
const ADD_MESSAGE_TO_THREAD = "ADD_MESSAGE_TO_THREAD";
const CONTINUE_MESSAGE = "CONTINUE_MESSAGE";

var openAiAction = CREATE_THREAD;

var assistantId = null;
var threadId = null;
var statusTranscription = CREATE_THREAD;

const CircularProgressWithLabel = (props) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography style={{color:"#FFF"}} variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};
const Assisstant = ({}) => {

    const [messages, setMessages] = useState([]);
    const [loadRes, setLoadRes] = useState(false);
    const chatInputRef = useRef();
    const chatContainerRef = useRef();
    const imagesInputRef = useRef(null);
    const assistantFileInputRef = useRef(null);

    const [imageGeneration, setImageGeneration] = useState(false);
    const [theFile, setTheFile] = useState({type: null});
    const [originalFiles, setOriginalFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState([]);
    const [uploadImageProgress, setUploadImageProgress] = useState(0);
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const [histories, setHystories] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [assisstants, setAssisstants] = useState([]);
    const [optsAssistants, setOptAssistants] = useState([]);
    const [activeAssisstant, setActiveAssisstant] = useState({id: ""});

    const [transcription, setTranscription] = useState(false);
    const [createSpeech, setCreateSpeech] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);

    const [formUrls, setFormUrls] = useState([]);
    const [formDataUrl, setFormDataUrl] = useState([]);
    const [attachAssistantFiles, setattachAssistantFiles] = useState([]);

    const [openDialogDeleteAssistant, setOpenDialogDeleteAssistant] = useState(false);
    const [loadDeleteAssistant, setLoadDeleteAssistant] = useState(false);
    const [statusAdd, setStatusAdd] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            // Si la touche Entrée est pressée sans Shift et la largeur de la fenêtre est supérieure à 800 pixels, gérer le message sortant
            if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (userInput !== ""){

                    createThreadAndRun();
                }
            }
        };

        // Ajouter un écouteur d'événements sur le composant
        document.addEventListener("keydown", handleKeyDown);

        // Nettoyer l'écouteur d'événements lorsque le composant est démonté
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [userInput]);

    const addFormUrl = () => {
        setFormUrls([...formUrls, { index: formUrls.length }]);
        setFormDataUrl([...formDataUrl, {}]);
    };

    const handleInputUrlChange = (index, data) => {

        const updatedFormData = [...formDataUrl];
        updatedFormData[index] = data;
        setFormDataUrl(updatedFormData);
    };

    const handleOpenDialogDeleteAssistant = ()=> {
        setOpenDialogDeleteAssistant(!openDialogDeleteAssistant);
    }

    const dialogActionDeleteAssistant = <>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
                <Button onClick={() => {
                    handleOpenDialogDeleteAssistant();
                }}>
                    Fermer
                </Button>
            </Box>

        </Box>
    </>;

    const handleDeleteAssistant = async (e, assistant) =>{

        console.log("Assistant: ", assistant);

        const userConfirmed = window.confirm('Voulez-vous vraiment supprimer cette assistant?');
        if (userConfirmed === true){

            setLoadDeleteAssistant(true);
            const { status, response } = await query({action: "DELETE_ASSISTANT", assistantId: assistant.id});
            setLoadDeleteAssistant(false);
            if (status){

                resetAllAssistantData();
                getAllAssisstants();

            }
        }
    }

    const dialogContentDeleteAssistant = <div style={{width:"580px"}}>
        <Grid container columns={12}>
            <Grid item md={12}>

                {assisstants.map((item, index) =>
                    <div
                        key={index}
                        className="file-item group-item"
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "5px",
                        }}
                    >
                        <Chip className={"truncate-text"} style={{fontSize:"10px"}}
                              label={item.name} color={"primary"}
                              size="small"
                        />

                        <div>
                            <Stack direction="row" spacing={2} alignItems={"center"}>

                                <div style={{marginLeft: "10px"}}>

                                    <Button
                                            color={"error"}
                                            variant={"outlined"}
                                            size="small"
                                            onClick= {(e) => handleDeleteAssistant(e, item)}>

                                        X
                                    </Button>

                                    {loadDeleteAssistant && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: blue[500],
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}

                                </div>
                            </Stack>
                        </div>

                    </div>
                )}


            </Grid>
        </Grid>
    </div>;


    var datasToSend = {
        currentChatId: "",
        action: "",
        messages: [],
        text: "",
        url: "", urlFile: "",
        imageGeneration: ""};

    const [assisstantFormData, setAssisstantFormData] = useState({
        assisstantName: "",
        assisstantInstruction: "",
        fileSearch : false,
        codeInterpreter: false,
        functionTool: false });

    const [hasNoTechnique, setHasNoTechnique] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            // Si la touche Entrée est pressée sans Shift et la largeur de la fenêtre est supérieure à 800 pixels, gérer le message sortant
            if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                console.log("Here sent");

            }
        };

        // Ajouter un écouteur d'événements sur le composant
        document.addEventListener("keydown", handleKeyDown);

        // Nettoyer l'écouteur d'événements lorsque le composant est démonté
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [userInput]);

    useEffect(() => {
        // retrieveRun();
        getAllAssisstants();

    }, []);

    const getAssistantUrls = async (assisId)=>{

        const { response, status } = await listAssisstant({ action: "GET_ASSISTANT_URLS", assistantId: assisId});

        console.log("ASSISSTANT: ", response);
        if ( status ){
            if (response?.urls){
                setFormUrls(response.urls);
            }
            // setFormDataUrl(response.urls);
        }
    }

    const getAllAssisstants = async ()=>{

        datasToSend["action"] = "LIST_ASSISSTANT";
        const { response, status } = await listAssisstant(datasToSend);

        console.log("ASSISSTANTS: ", response);
        if ( status ){

            var fetchedAssisstants = response.data;
            setAssisstants(fetchedAssisstants);
            var formatedOptionsAssistant = fetchedAssisstants.map((a) => {
                return {
                    value: a.id,
                    label: a.name
                }
            });
            console.log("Opts assistants: ", formatedOptionsAssistant);
            setOptAssistants(formatedOptionsAssistant);
        }
    }

    const retrieveRun = async ()=>{

        var action = "RETRIEVE_RUN";
        var datas = { currentChatId: "", action: action, messages: [], text: "", url: "", imageGeneration: ""};

        // const { response } = await query(datas);

    }

    const getAllHystory = async () => {
        var { status, fetchedHistories } = await getHystories();
        if (status){

            setHystories(fetchedHistories);
        }
    };

    const handleOutgoingChat = () => {
        const userText = chatInputRef.current.value.trim(); // Obtenez la valeur de chatInput et supprimez les espaces supplémentaires
        if (!userText) return; // Si chatInput est vide, retournez d'ici

        // Effacez le champ de saisie et réinitialisez sa hauteur
        //chatInputRef.current.value = "";
        //chatInputRef.current.style.height = `${initialInputHeight}px`;

        const html = `<div className="chat-content">
                        <div className="chat-details">
                            <img src="/media/images/user.jpg" alt="user-img">
                            <p>${userText}</p>
                        </div>
                    </div>`;

        // Créez un élément de chat sortant avec le message de l'utilisateur et ajoutez-le au conteneur de chat
        // const outgoingChatDiv = createChatElement(html, "outgoing");
        /*chatContainerRef.current.querySelector(".default-text")?.remove();
        chatContainerRef.current.appendChild(outgoingChatDiv);
        chatContainerRef.current.scrollTo(0, chatContainerRef.current.scrollHeight);
        setTimeout(showTypingAnimation, 500);*/
    };

    const handleChangeFormAssisstant = (e)=>{

        setAssisstantFormData({...assisstantFormData, [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value});

    }

    const handleQueryListAssisstantFiles = async (foundAssistant) => {

        const url = BASE_URI + "/assisstant/query";
        var datas = {
            assistantId: foundAssistant?.id,
            action: "LIST_ASSISTANT_FILE"
        };
        console.log("Datas to send: ", datas);

        try {

            const { data } = await axios.post(url, datas, {
                headers: {
                    "Content-Type": "application/json",
                }
            });
            console.log("Data LIST ATTACH FILE: ", data);

            setattachAssistantFiles(data.data);
            // return { status: true, response: data };
            // await getAllAssisstants();

        } catch (error) {
            const message =
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message;

            console.log("ERROR: ", message);
            // return { status: false, message: message, response: "error" };
        }


    }

    const resetAllAssistantData = ()=>{

        setAssisstantFormData({...(assisstantFormData), assisstantName: "", assisstantInstruction: "", fileSearch: false, functionTool: false, codeInterpreter: false});
        setFormUrls([]);
        setActiveAssisstant({id: ""});
        setUserInput("");
        setStatusAdd(false);

    }

    const handleQueryAssisstant = async (e, action) => {

        if (action == "NEW_FORM_ASSISTANT"){

            resetAllAssistantData();

        }else {

            console.log("Data: ", assisstantFormData);
            const url = BASE_URI + "/assisstant/query";
            var datas = {
                assistantId: activeAssisstant?.id,
                action: action,
                assistantValues: assisstantFormData,
                assistantUrls: formDataUrl,
            };
            console.log("Datas to send: ", datas);
            console.log("Form data: ", formDataUrl);

            try {

                const { data } = await axios.post(url, datas, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                console.log("Data: ", data);

                var assistantMessage;

                if(data.hasOwnProperty("error")){

                    assistantMessage = { role: 'assistant', content: data.content };
                    alert(data?.error?.code);
                    setLoadRes(false);

                }else {

                    if (action === "CREATE_ASSISTANT"){
                        if (data?.id){

                            await getAllAssisstants();
                            setActiveAssisstant(data);
                        }
                    }
                    setStatusAdd(true);

                }

                /*if (data.hasOwnProperty("error")){

                    assistantMessage = { role: 'assistant', content: data.content };

                }else {

                    currentChatId = data.currentChatId;

                    if (imgGen){

                        var msgDisplay = <div className="image-loader-container">
                                                        <div className={"del-top-right"}>
                                                            <FileDownloadIcon style={{cursor:"pointer"}} onClick={(e)=> handleSaveAs(e, data.url)} sx={{ fontSize: 30 }} />
                                                        </div>
                                                        <img src={data.url} alt="Uploaded" className="uploaded-image" />
                                                </div>;
                        assistantMessage = { role: 'assistant', content: msgDisplay };
                        setUploadImageProgress(0);
                    }else {

                        assistantMessage = { role: 'assistant', content: data.content };
                    }

                    console.log("Assisstant msg: ", assistantMessage);
                }

                newMessages = [...newMessages, assistantMessage];
                console.log("New message: ", newMessages);
                setLoadRes(false);
                setMessages(newMessages);

                chatContainerRef.current.scrollTo(0, chatContainerRef.current.scrollHeight);*/

                // return { status: true, response: data };


            } catch (error) {
                const message =
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message;

                console.log("ERROR: ", message);
                // return { status: false, message: message, response: "error" };
            }
        }

    }

    const createThreadAndRun = async () => {
        console.log("Type message: ", userInput);

        if (!userInput) return;

        if (!activeAssisstant.hasOwnProperty("id")){
            alert("Séléctionner un assistant.");
        }else {


            var userMessage = { role: 'user', content: userInput.trim() };
            var newMessages = [...messages, userMessage];

            setMessages(newMessages);

            var publicUrlFile = "";

            var datas = {
                assistantId: activeAssisstant?.id,
                threadId: threadId,
                currentChatId: currentChatId,
                action: statusTranscription,
                messages: newMessages,
                text: userInput, url:
                publicUrlFile,
                imageGeneration: imgGen
            };

            setUserInput('');
            console.log("Datas: ", datas);

            const url = BASE_URI + "/assisstant/query";

            try {

                setLoadRes(true);

                if (statusTranscription === "CREATE_SPEECH"){

                    const { data } = await axios.post(url, datas, {
                        responseType: 'blob', // Important to handle binary data
                    });

                    // Create a URL for the audio blob
                    const urlBlob = URL.createObjectURL(data);
                    setAudioUrl(urlBlob);
                    setLoadRes(false);

                }else {

                    const { data } = await axios.post(url, datas, {
                        headers: {
                            "Content-Type": "application/json",
                        }
                    });

                    var assistantMessage;

                    console.log("Result data: ", data);

                    if (data.hasOwnProperty("error")){

                        assistantMessage = { role: 'assistant', content: data.content };

                    }else {

                        // currentChatId = data.currentChatId;
                        var res = data.data;
                        var firstData = res[0];
                        var contentValue = firstData["content"];
                        var firstContent = contentValue[0];
                        var textObject = firstContent["text"];

                        assistantMessage = { role: 'assistant', content: textObject.value };
                        console.log("Assisstant msg: ", assistantMessage);

                        // openAiAction = CONTINUE_MESSAGE;
                        // assistantId = data.assistantId;
                        // threadId = data.threadId;

                    }

                    newMessages = [...newMessages, assistantMessage];
                    console.log("New message: ", newMessages);
                    setLoadRes(false);
                    setMessages(newMessages);

                }



                /*const result = JSON.parse(data);
                console.log("Result: ", result);*/


                // chatContainerRef.current.scrollTo(0, chatContainerRef.current.scrollHeight);
                // return { status: true, response: data };
            } catch (error) {
                const message =
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message;

                console.log("ERROR: ", message);
                // return { status: false, message: message, response: "error" };
            }
        }

    };

    const copyResponse = (copyBtn) => {
        // Copy the text content of the response to the clipboard
        const reponseTextElement = copyBtn.parentElement.querySelector("p");
        navigator.clipboard.writeText(reponseTextElement.textContent);
        copyBtn.textContent = "done";
        setTimeout(() => copyBtn.textContent = "content_copy", 1000);
    }

    const traitementMessage = (msg, key)=>{
        var res = "";

        console.log("Msg: ", msg);
        if (msg.role === "assistant"){
            // Display the typing animation and call the getChatResponse function
            res = <div className="chat incoming" key={key}>
                <div className="chat-content" >
                    <div className="chat-details">
                        <img className={"img-chat"} src="/media/images/chatbot.jpg" alt="chatbot-img" />
                        <div style={{marginLeft:"10px"}}><pre><code>{msg.content}</code></pre></div>
                    </div>
                    <span
                        onClick={(e)=> {

                        }}
                        style={{marginLeft: "10px", fontSize: "14px"}}
                        className="material-symbols-rounded">
                        <Stack direction={"row"}>
                            <div><ContentCopyIcon sx={{ fontSize: 20 }} /> </div>
                            <div>Copier</div>
                        </Stack>
                    </span>
                </div>
            </div>;
        }else if (msg.role === "user"){
            res = <div className="chat outgoing" key={key}>
                <div className="chat-content">
                    <div className="chat-details">
                        <img className={"img-chat"} src="/media/images/user.jpg" alt="user-img" />
                        <div style={{marginLeft:"10px"}}>{msg.content}</div>
                    </div>
                </div>
            </div>;
        }
        return res;
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const resetSearch = ()=>{
        setMessages([
            { role: 'system', content: 'You are a helpful assistant.' }
        ]);
        setImageGeneration(false);
        imgGen = false;
        sentFiles = [];
        currentChatId = null;
        setOriginalFiles([]);
    }

    const handleButtonAddImageClick = (e, typeFile = "audio") => {
        // Clique sur l'élément input de type "file" lorsqu'on clique sur le bouton
        if (typeFile === "assistantFile"){
            assistantFileInputRef.current.click();
        }else {
            imagesInputRef.current.click();
        }

    };

    const handleChangeGenerateImage = (event) => {
        var status = event.target.checked;
        console.log("Status: ", status);
        setImageGeneration(status);
        imgGen = status;
        if (status){
            setOriginalFiles([]);
            sentFiles = [];
        }
    };

    const handleChangeForAudio = (event) => {
        var status = event.target.checked,
            name = event.target.name,
            statusConvertAudioAndCreateSpeech = false;
        console.log("Status: ", status);
        if (name === "createSpeech"){
            setCreateSpeech(status);
            if (status){
                statusTranscription = "CREATE_SPEECH";
                statusConvertAudioAndCreateSpeech = true;
                setTranscription(false);
            }
        }else {
            setTranscription(status);
            if (status){
                statusTranscription = "CONVERT_AUDIO_TO_TEXT";
                statusConvertAudioAndCreateSpeech = true;
                setCreateSpeech(false);
            }
        }

        //##Control if asking assistant##//
        if (!statusConvertAudioAndCreateSpeech){

            statusTranscription = CREATE_THREAD;
        }

    };

    const LinearProgressWithLabel = (props) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    const formatUUIDWithPrefix = (prefix = null) => {
        const uuid = uuidv4(); // Génère un UUID v4 unique
        if (prefix){
            return prefix + uuid;
        }else {
            return uuid;
        }
    }

    const handleUploadFile = async (event)=> {

        var allFiles = event.target.files;

        var uploadedFiles = [];
        // Array.from pour convertir la liste des fichiers en tableau
        Array.from(allFiles).forEach((file, i) => {
            var item = {theFile: file, fileName: "", progress: 0 };
            uploadedFiles = [...uploadedFiles, item];
        });
        var uFiles = [...(originalFiles), ...uploadedFiles];
        setOriginalFiles(uFiles);

        // Reset progress state
        setUploadProgress(Array(uploadedFiles.length).fill(0));

        for (let i = 0; i < uploadedFiles.length; i++) {
            const file = uploadedFiles[i];
            // Create form data
            const formData = new FormData();
            const UUID = formatUUIDWithPrefix();
            formData.append("theFile", file["theFile"]);
            formData.append("uuid", UUID);

            // Send request to upload file
            try {
                const response = await axios.post(`${BASE_CONVERSION_URI}/conversion/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        // Update upload progress
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setOriginalFiles(prevFiles => {
                            const newProgress = [...prevFiles];
                            var foundIndex = prevFiles.findIndex(function(obj) {
                                return obj.theFile.name === file.theFile.name;
                            });
                            if (foundIndex !== -1) {
                                newProgress[foundIndex].progress = progress;
                            }
                            return newProgress;
                        });
                    }
                });
                var result = response.data;
                var infosFile = {fileName: result.fileName, urlFile: result.urlFile, originalFileName: file.theFile.name};
                sentFiles = [...sentFiles, infosFile];

            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
            }
        }
    }

    const handleUploadAssistantFile = async (event)=> {

        var allFiles = event.target.files;

        var uploadedFiles = [];
        // Array.from pour convertir la liste des fichiers en tableau
        Array.from(allFiles).forEach((file, i) => {
            var item = {theFile: file, fileName: "", progress: 0 };
            uploadedFiles = [...uploadedFiles, item];
        });
        //var uFiles = [...(originalFiles), ...uploadedFiles];
        //setOriginalFiles(uFiles);

        // Reset progress state
        // setUploadProgress(Array(uploadedFiles.length).fill(0));

        for (let i = 0; i < uploadedFiles.length; i++) {
            const file = uploadedFiles[i];
            // Create form data
            const formData = new FormData();
            const UUID = formatUUIDWithPrefix();
            formData.append("theFile", file["theFile"]);
            formData.append("uuid", UUID);
            formData.append("action", "UPLOAD_ASSISTANT_FILE");
            formData.append("assistantId", activeAssisstant.id);

            // Send request to upload file
            try {

                const url = BASE_URI + "/assisstant/query";
                const response = await axios.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        // Update upload progress
                        /*const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setOriginalFiles(prevFiles => {
                            const newProgress = [...prevFiles];
                            var foundIndex = prevFiles.findIndex(function(obj) {
                                return obj.theFile.name === file.theFile.name;
                            });
                            if (foundIndex !== -1) {
                                newProgress[foundIndex].progress = progress;
                            }
                            return newProgress;
                        });*/
                    }
                });

                var result = response.data;
                console.log("Result: ", result);

                /*var infosFile = {fileName: result.fileName, urlFile: result.urlFile, originalFileName: file.theFile.name};
                sentFiles = [...sentFiles, infosFile];*/

                handleQueryListAssisstantFiles(activeAssisstant);

            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
            }
        }
    }

    const handleDeleteImage = (e, file, index)=>{

        const userConfirmed = window.confirm('Voulez-vous vraiment supprimer ce historique?');
        if (userConfirmed === true){
            var updatedFiles = originalFiles.filter((item) => {
                return item.theFile.name !== file.name;
            });

            var updatedSentFiles = sentFiles.filter((item)=> {
                return item.originalFileName !== file.name;
            });
            setOriginalFiles(updatedFiles);
            sentFiles = updatedSentFiles;
        }
    }

    const handleSaveAs = async (e, urlToTheFile)=> {

        // Créer un objet URL à partir du Blob

        /*const url = URL.createObjectURL(urlToTheFile);
        // Créer un élément de lien
        const link = document.createElement('a');
        // Définir l'URL du lien sur l'URL du Blob
        link.href = url;
        // Définir le nom de fichier lors du téléchargement
        link.download = file.fileName;
        // Ajouter le lien au DOM
        document.body.appendChild(link);
        // Simuler un clic sur le lien pour déclencher le téléchargement
        link.click();
        // Nettoyer
        URL.revokeObjectURL(url);
        document.body.removeChild(link);*/

        window.open(urlToTheFile, "_blank");

    }

    const deleteBtn = (file, index)=> {
        return <div className="del-top-right">
            <Button
                style={{minWidth:"40px"}}
                size={"small"}
                variant={"contained"}
                color={"error"} onClick={(e)=> handleDeleteImage(e, file, index)}>
                X
            </Button>
        </div>
    };

    const displaySelectedImage = (currentFile, index)=> {

        var file = currentFile.theFile;
        var fileName = file.name, res, type = file.type,
            typeSplited = type.split("/"),
            typeFirstPart = typeSplited[0];
        var fileNameSplited = fileName.split(".");
        var partName = fileNameSplited[0],
            extension = fileNameSplited[1],
            extensionLower = extension.toLowerCase();

        if (typeFirstPart !== "image"){
            res = <Grid item sm={12} lg={4} key={index} className="image-item" style={{position:"relative"}}>
                <FilePresentIcon key={index} sx={{ fontSize: 40 }} />
                <p className="image-name">
                    <span style={{fontSize: "0.75rem"}}>{file.name}</span>{" "}
                </p>
                {deleteBtn(file, index)}

                <Box sx={{ width: '100%' }}>
                    <LinearProgressWithLabel value={currentFile.progress} />
                </Box>
            </Grid>
        }else {
            res = <Grid item sm={12} lg={4} key={index}>
                <div className="img-c">
                    <img src={URL.createObjectURL(file)} alt={`Image ${index}`}
                         style={{
                             width: '100%',
                             height: 'auto',
                             display: 'block'}} className="image-preview" />

                    <p className="image-name">
                        <span style={{fontSize: "0.75rem", color:"#fff" }}>{file.name}</span>{" "}

                        {currentFile.progress === 100 && (
                            <span style={{color:"rgb(49, 162, 76)", fontFamily:"bolt!important"}}>ok <CheckIcon sx={{ fontSize: 15 }} /></span>
                        )}

                    </p>

                    {currentFile.progress < 100 && (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel value={currentFile.progress} />
                        </Box>
                    )}
                    {deleteBtn(file, index)}
                </div>
            </Grid>
        }
        return res;

    }

    const handleClickHystory = async (history)=> {

        currentChatId = history["id"];
        var {status, fetchedHistory} = await getOneHystory(history["id"]);

        if (status){
            var historyMsg = [];
            var theHistory = fetchedHistory;

            console.log("The history: ", theHistory);
            historyMsg = theHistory["content"];

            console.log("Messages: ", historyMsg);
            setMessages(historyMsg);
        }
    }
    const displayHystory = (item, index)=> {

        var res = null;
        var contents = item["content"];
        if (contents.length > 2){
            var firstUserMessage = contents[1];
            res = <li key={index}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <div className={"text-elipsis"} onClick={(e)=> {
                        handleClickHystory(item);
                    }} >{firstUserMessage["content"]}</div>
                    <MoreHorizIcon sx={{ fontSize: 20 }} />
                </Stack>
            </li>;
        }

        return res;
    }

    const handleChangeAssistant = (event)=> {

        var theValue = event.target.value;

        const foundAssistant = assisstants.find(item => item.id === theValue);

        console.log("Found Assistant: ", foundAssistant);

        var assistantTools = foundAssistant.tools;

        let fileSearch = assistantTools.find(a => a.type === "file_search");

        let functionTool = assistantTools.find(a => a.type === "function");

        setActiveAssisstant(foundAssistant);

        setAssisstantFormData({...assisstantFormData, assisstantName: foundAssistant.name, assisstantInstruction: foundAssistant.instructions, fileSearch: fileSearch !== undefined, functionTool: functionTool !== undefined });

        getAssistantUrls(theValue);

        handleQueryListAssisstantFiles(foundAssistant);

    }

    return (
        <>
            <Container maxWidth="xl" style={{marginTop:"20px"}}>

                <Grid container columns={12} style={{marginTop:"20px"}}>

                    <Grid item sm={12} xs={12} lg={4} >

                        <Stack justifyContent={"start"} direction={"row"}>
                            <div>
                                <Typography variant="button" display="block" gutterBottom>
                                    Playground
                                </Typography>
                            </div>
                            <div></div>
                        </Stack>

                        <Stack justifyContent={"start"} direction={"row"} style={{ padding: "5px", marginTop:"20px"}}>
                            <div>
                                <Button color={"success"} variant="outlined" size="small" onClick={ (e) => handleQueryAssisstant(e, "NEW_FORM_ASSISTANT")}>
                                    Nouveau
                                </Button>
                            </div>
                            <div></div>
                        </Stack>

                        <Stack justifyContent={"start"}  sx={{ padding: "5px" }}>

                            <div style={{textAlign:"left", marginBottom:"20px"}}>
                                <Button color={"error"}
                                        variant="outlined"
                                        size="small"
                                        onClick={ (e) => handleOpenDialogDeleteAssistant()} >
                                    Supprimer un assistant
                                </Button>
                            </div>

                            <div style={{textAlign:"left"}}>

                                { statusAdd &&
                                    <Alert severity="success">Assistant enregistrer avec succées</Alert>
                                }

                                <FormControl fullWidth size="medium" error={hasNoTechnique}>
                                    <InputLabel id="demo-simple-select-label">
                                        ASSISSTANT
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="assisstant"
                                        value={activeAssisstant.id}
                                        label="Téchnique d'impression"
                                        onChange={handleChangeAssistant}
                                    >
                                        {optsAssistants.map(
                                            (item, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    <FormHelperText>{ hasNoTechnique ? "Assisstant ne peut être null" : "" }</FormHelperText>
                                </FormControl>
                            </div>

                            <div style={{textAlign:"left", marginTop:"20px"}}>
                                <TextField fullWidth
                                    required
                                    id="outlined-required"
                                    label="Assisstant"
                                    helperText={"Nom de l'assisstant"}
                                    name={"assisstantName"}
                                    value={assisstantFormData.assisstantName}
                                    onChange={handleChangeFormAssisstant}
                                />
                            </div>

                            <div style={{textAlign:"left", marginTop:"20px"}}>
                                <TextField fullWidth
                                    id="outlined-multiline-static"
                                    label="Instructions"
                                    multiline
                                    rows={4}
                                    value={assisstantFormData.assisstantInstruction}
                                    name={"assisstantInstruction"}
                                    onChange={handleChangeFormAssisstant}
                                />
                            </div>

                            <div style={{textAlign:"left"}}>DATASOURCE URLS</div>

                            <div style={{textAlign:"left"}}>

                                <Button variant="contained" size="small" startIcon={<AddIcon />} onClick={addFormUrl}>
                                    Ajouter
                                </Button>

                                {formUrls.map((form, index) => (
                                    <UrlForm
                                        key={index}
                                        index={index}
                                        defaultValue={form?.url}
                                        handleInputChange={handleInputUrlChange}
                                    />
                                ))}

                            </div>

                            <div style={{textAlign:"left", marginTop:"20px" }}>OUTILS</div>

                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>

                                <div>
                                    <FormControlLabel
                                        control={
                                            <Switch  checked={assisstantFormData.fileSearch}
                                                     name="fileSearch"
                                                     onChange={handleChangeFormAssisstant} />
                                        }

                                        label="Recherche de fichier"
                                    />

                                    <div>
                                        {attachAssistantFiles.map((f, index) => (
                                            <Chip style={{fontSize:"10px"}} key={f.id} label={f.id} color={"success"} size="small" />
                                        ))}
                                    </div>
                                </div>

                                { activeAssisstant?.id &&
                                    <div>
                                        <Button startIcon={<FilePresentIcon />}
                                                variant={"outlined"} size="small"
                                                onClick= {(e) => handleButtonAddImageClick(e, "assistantFile")}>
                                            <VisuallyHiddenInput
                                                ref={assistantFileInputRef}
                                                type="file"
                                                onChange={(e) => {
                                                    handleUploadAssistantFile(e);
                                                }}
                                            /> Upload
                                        </Button>
                                    </div>
                                }

                            </Stack>

                            <div style={{textAlign:"left"}}>
                                <div>

                                    <FormControlLabel
                                        control={
                                            <Switch  checked={assisstantFormData.functionTool}
                                                     name="functionTool"
                                                     onChange={handleChangeFormAssisstant} />
                                        }

                                        label="Api externe"
                                    />

                                </div>
                            </div>

                            <div style={{textAlign:"left"}}>
                                <Button startIcon={<CheckIcon />} variant={"contained"} size="small" onClick={ (e) => handleQueryAssisstant(e, activeAssisstant?.name ? "MODIFY_ASSISTANT" : "CREATE_ASSISTANT")}>
                                    Valider
                                </Button>
                            </div>


                        </Stack>

                    </Grid>

                    <Grid item sm={12} xs={12} lg={8}>

                        <Stack justifyContent={"start"} direction={"row"} alignItems={"end"}>
                            <div>
                                <Typography variant="button" display="block" gutterBottom>
                                    ASSISTANT {activeAssisstant?.id}
                                </Typography>
                            </div>
                        </Stack>

                        <div className="container-chat">

                            <div ref={chatContainerRef} className="chat-container" style={{height:"100vh", display:"flex", flexDirection:"column"}}>

                                {messages.map((msg, index) => {
                                    return traitementMessage(msg, index);
                                })}
                                {loadRes && <div className={"chat incoming"}>
                                    <div className="chat-content">
                                        <div className="chat-details">
                                            <img className={"img-chat"} src="/media/images/chatbot.jpg" alt="chatbot-img"/>
                                            <div className="typing-animation">
                                                <div className="typing-dot" style={{ '--delay': '0.2s' }}></div>
                                                <div className="typing-dot" style={{ '--delay': '0.3s' }}></div>
                                                <div className="typing-dot" style={{ '--delay': '0.4s' }}></div>
                                            </div>
                                        </div>
                                        <span onClick="copyResponse(this)" className="material-symbols-rounded"></span>
                                    </div>
                                </div>}
                            </div>

                            <div className="typing-container">

                                <Grid container direction="row" spacing={2}>
                                    { originalFiles.map((item, index) => (
                                        displaySelectedImage(item, index)
                                    ))}
                                </Grid>

                                <Grid container direction="column">
                                    <Grid item sm={12}>
                                        <div className="typing-content">
                                            <div className="typing-controls">
                                                <span id="upload-btn" style={{borderRadius: "4px 0 0 4px"}} className="material-symbols-rounded"
                                                      onClick= {(e) => handleButtonAddImageClick(e)}>
                                                    <VisuallyHiddenInput
                                                        ref={imagesInputRef}
                                                        type="file"
                                                        onChange={(e) => {
                                                            handleUploadFile(e);
                                                        }}
                                                    />
                                                    <AttachFileIcon/>
                                                </span>
                                            </div>
                                            <div className="typing-textarea">
                                            <textarea ref={chatInputRef}
                                                      id="chat-input"
                                                      value={userInput}
                                                      onChange={(e)=> {
                                                          const theValue = e.target.value;
                                                          setUserInput(theValue);
                                                      }}
                                                      spellCheck="false" placeholder="Entrez une invite ici" required>
                                            </textarea>

                                                <span id="send-btn" className="material-symbols-rounded" onClick={(e)=> {
                                                    createThreadAndRun();
                                                }}
                                                >
                                                    <SendIcon/>
                                                </span>
                                            </div>

                                        </div>

                                        <div>

                                            <div style={{textAlign:"left", marginBottom:"10px", marginTop:"10px"}}>

                                                <Typography variant="button" gutterBottom sx={{color:"red"}}>
                                                    Audio Recorder
                                                </Typography>

                                            </div>

                                            <div style={{textAlign:"left"}}>


                                                <FormControlLabel
                                                    control={
                                                        <Switch  checked={transcription}
                                                                 onChange={handleChangeForAudio}
                                                                 name="transcription" />
                                                    }
                                                    style={{color:"#fff"}}
                                                    label="Trancription audio"
                                                />

                                                <FormControlLabel
                                                    control={
                                                        <Switch  checked={createSpeech}
                                                                 onChange={handleChangeForAudio}
                                                                 name="createSpeech" />
                                                    }
                                                    style={{color:"#fff"}}
                                                    label="Create a speech"
                                                />

                                            </div>

                                            {transcription &&
                                                <AudioRecorderComponent
                                                    setUserInput={setUserInput}
                                                />
                                            }

                                            { audioUrl &&
                                                <div style={{textAlign:"left", marginTop:"10px"}}>
                                                    <audio style={{height:"40px"}} controls src={audioUrl} />
                                                </div>
                                            }


                                        </div>

                                    </Grid>
                                </Grid>

                            </div>
                        </div>

                    </Grid>

                </Grid>



            </Container>

            <Popup open={openDialogDeleteAssistant}
                   children={dialogContentDeleteAssistant}
                   dialogActions={dialogActionDeleteAssistant}
                   title={"Suppression assistant"}
                   onDialogClose={handleOpenDialogDeleteAssistant} />

        </>
    )
}

export default Assisstant