import axios from "axios";

const BASE_URI = process.env.REACT_APP_BASE_CONVERSION_URI;

const headersMultiPartForm = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

const headersAppJson = {
    headers: {
        "Content-Type": "application/json",
    },
};

export const query = async (datas) => {
    const url = BASE_URI + "/assisstant/query";
    try {
        const { data } = await axios.post(url, datas, headersAppJson);
        console.log("Data get: ", data);
        return { status: true, response: data };
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return { status: false, message: message, response: "error" };
    }
};

export const listAssisstant = async (datas) => {
    const url = BASE_URI + "/assisstant/query";
    try {
        const { data } = await axios.post(url, datas, headersMultiPartForm);
        console.log("Data get: ", data);

        return { status: true, response: data };
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return { status: false, message: message, response: "error" };
    }
};

export const getHystories = async () => {
    const url = BASE_URI + "/assisstant/histories";
    const datas = {};
    try {
        const { data } = await axios.get(url, datas);
        return { status: true, fetchedHistories: data.histories };
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        console.log("here error: ", error);
        return { status: false, error: message };
    }
};

export const getOneHystory = async (historyId) => {
    const url = BASE_URI + "/assisstant/history";
    const datas = {historyId};
    try {
        const { data } = await axios.post(url, datas, headersAppJson);
        return { status: true, fetchedHistory: data.history };
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        console.log("here error: ", error);
        return { status: false, error: message };
    }
};
