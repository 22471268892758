import { BehaviorSubject } from "rxjs";

class ImagesRxjs {
    images = [];
    constructor() {
        this.images = new BehaviorSubject([]);
    }

    // Méthode pour ajouter un nouvel objet au tableau d'objets
    addImage(image) {
        const currentImages = this.images.getValue();
        /*const index = currentValue.findIndex(
            (obj) => obj.index === image.value
        );*/
        //if (index === -1 || index === undefined) {
        // Si l'objet n'existe pas, l'ajouter
        const updatedImages = [...currentImages, image];
        this.images.next(updatedImages);
        //}
    }

    updateImage(image) {
        const currentImages = this.images.getValue();
        const index = currentImages.findIndex(
            (obj) => obj.uuid === image.uuid
        );
        if (index !== -1 && index !== undefined) {
            const updatedImages = currentImages;
            updatedImages[index] = image;
            this.images.next(updatedImages);
        }
    }

    removeImage(image) {
        const currentImages = this.images.getValue();
        const updatedImages = currentImages.filter(
            (item) => item.uuid !== image.uuid
        );
        //if (index === -1 || index === undefined) {
        this.images.next(updatedImages);
    }

    setImages(images, init = false) {
        var currentImages = this.images.getValue();
        if (init){
            currentImages = [];
        }
        const updatedImages = [...currentImages, ...images];
        this.images.next(updatedImages);
    }

    getImagesObservable() {
        return this.images.asObservable();
    }
}

export default new ImagesRxjs();
