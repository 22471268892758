import React, { useState, useRef } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from "@ffmpeg/util";

import lamejs from 'lamejs';
import Utils from "../Utils/Utils";
import axios from "axios";

const ffmpeg = new FFmpeg();
const BASE_URI = process.env.REACT_APP_BASE_URI;
// const ffmpeg = FFmpeg({ log: true });

const AudioRecorderComponent = ({ setUserInput }) => {

    const audioRecorder = useAudioRecorder();
    const [isFFmpegLoaded, setIsFFmpegLoaded] = useState(false);
    const [mp3Blob, setMp3Blob] = useState(null);
    const [webmBlob, setWebmBlob] = useState(null);
    const [webmBlobUrl, setWebmBlobUrl] = useState(null);

    const loadFFmpeg = async () => {
        if (!isFFmpegLoaded) {
            await ffmpeg.load();
            setIsFFmpegLoaded(true);
        }
    };

    const blobToWav = async (blob) => {
        await loadFFmpeg();

        // Write the input file to FFmpeg's virtual file system
        ffmpeg.FS('writeFile', 'input.webm', await fetchFile(blob));

        // Run the FFmpeg command to convert WebM to WAV
        await ffmpeg.run('-i', 'input.webm', 'output.wav');

        // Read the output file from FFmpeg's virtual file system
        const data = ffmpeg.FS('readFile', 'output.wav');
        return new Blob([data.buffer], { type: 'audio/wav' });
    };

    const handleSaveRecording = async (blob) => {

        if (!blob) {
            console.error('No blob received for recording.');
            return;
        }

        try {
            console.log('Received blob:', blob);

            // Convert to WAV
            const wavBlob = await blobToWav(blob);
            const arrayBuffer = await wavBlob.arrayBuffer();

            const wav = lamejs.WavHeader.readHeader(new DataView(arrayBuffer));
            if (!wav) {
                console.error('Failed to read WAV header.');
                return;
            }

            console.log('WAV Header:', wav);
            const samples = new Int16Array(arrayBuffer, wav.dataOffset, wav.dataLen / 2);
            console.log('Samples:', samples);

            const mp3Encoder = new lamejs.Mp3Encoder(1, wav.sampleRate, 128);
            const mp3Data = [];
            let remaining = samples.length;
            const maxSamples = 1152;

            for (let i = 0; remaining >= maxSamples; i += maxSamples) {
                const mono = samples.subarray(i, i + maxSamples);
                const mp3buf = mp3Encoder.encodeBuffer(mono);
                if (mp3buf.length > 0) {
                    mp3Data.push(mp3buf);
                }
                remaining -= maxSamples;
            }

            const d = mp3Encoder.flush();
            if (d.length > 0) {
                mp3Data.push(d);
            }

            const mp3Blob = new Blob(mp3Data, { type: 'audio/mp3' });
            setMp3Blob(mp3Blob);
        } catch (error) {
            console.error('Error processing recording:', error);
        }
    };

    const downloadMp3 = () => {
        if (!mp3Blob) return;

        const url = URL.createObjectURL(mp3Blob);

        const a = document.createElement('a');

        a.style.display = 'none';
        a.href = url;
        a.download = 'recording.mp3';
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);

    };

    const addAudioElement = async (blob) => {

        console.log('Received blob:', blob);

        setWebmBlob(blob);

        const url = URL.createObjectURL(blob);
        setWebmBlobUrl(url);


        // Create form data
        const formData = new FormData();

        const UUID = Utils.formatUUIDWithPrefix();
        formData.append("theFile", blob, 'audio.mp3');
        formData.append("uuid", UUID);
        formData.append("action", "CONVERT_AUDIO_TO_TEXT");

        // Send request to upload file
        try {
            const response = await axios.post(`${BASE_URI}/assisstant/query`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.hasOwnProperty("error")){

                console.log("TExt error: ", response);
            }else {

                var result = response.data;
                console.log("TExt datas: ", result);
                setUserInput(result.text);

            }

        } catch (error) {
            console.error(`Error uploading file :`, error);

        }

        /*const audio = document.createElement("audio");
        audio.src = url;*/

        /*audio.controls = true;
        document.body.appendChild(audio);*/

    };

    return (
        <div>

            <AudioRecorder
                onRecordingComplete={addAudioElement}
                recorderControls={audioRecorder}
                downloadFileExtension="mp3"
            />

            { webmBlob &&
                <div style={{textAlign:"left"}}>
                    <audio style={{height:"40px"}} controls>
                        <source src={webmBlobUrl} type="audio/mp3" />
                    </audio>
                </div>

            }

            {mp3Blob && (
                <button onClick={downloadMp3}>
                    Download MP3
                </button>
            )}
        </div>
    );
};

export default AudioRecorderComponent;
