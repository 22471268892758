import "./App.css";
import { AppRouter } from "./Routes/router";

function App() {
  return (
    <div className="App" style={{height: "100vh", overflow: "auto"}}>
      <AppRouter />
    </div>
  );
}

export default App;
