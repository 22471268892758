import React, {useState} from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from "@mui/material/Stack";
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import {useNavigate} from "react-router-dom";
import {Button, Divider, Grid} from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import imagesRxjs from "../Service/imagesRxjs";
import ImageIcon from '@mui/icons-material/Image';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ApiIcon from '@mui/icons-material/Api';
import AssistantIcon from '@mui/icons-material/Assistant';

const CONVERSION_PIC_URI = process.env.REACT_APP_CONVERSION_PIC_URI;

const HamburgerMenu = () => {

    styled(Paper)(({theme}) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClickAnchor = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/imprimantes/config");
    };

    const handleClickImages = () => {
        imagesRxjs.setImages([], true);
        navigate("/images/planches");
    };

    const handleClickHome = () => {
        navigate("/");
    };

    const handleClickOpenApi = () => {
        navigate("/asks");
    };

    const handleClickAssistant = () => {
        navigate("/assistants");
    };

    const buttonConfig = <>
        <Button style={{color: "white"}} onClick={handleClick} startIcon={<PermDataSettingIcon/>}>
            GESTION IMPRIMANTE
        </Button>
    </>

    const buttonConfig2 = <button
        className={"btn-imprimante"}
        onClick={handleClick}
    >
        <PermDataSettingIcon/> Gestions des imprimantes
    </button>

    const menu = <>
        <div>
            <Button style={{color: "white"}} onClick={handleClickHome} startIcon={<PrintIcon/>}>
                IMPRIMANTE
            </Button>

        </div>
        <div>
            <Button style={{color: "white"}} onClick={handleClickImages} startIcon={<ImageIcon/>}>
                AJOUT IMAGES
            </Button>

        </div>
        <div>
            <Button style={{color: "white"}} onClick={() => {
                window.open(CONVERSION_PIC_URI, '_blank', 'noopener,noreferrer');
            }}
                    startIcon={<SettingsSuggestIcon/>}>
                COVERSION IMAGES
            </Button>

        </div>
        <div>
            <Button style={{color: "white"}} onClick={handleClickOpenApi} startIcon={<ApiIcon/>}>
                OPEN API
            </Button>

        </div>
        <div>
            <Button style={{color: "white"}} onClick={handleClickAssistant} startIcon={<AssistantIcon/>}>
                ASSISTANT
            </Button>

        </div>
    </>;


    return (
        <div>
            <div className={"box-header"}
                 style={{padding: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <Stack direction="row" spacing={2} alignItems={"center"} justifyContent={"center"}>
                        <div>
                            <h2>PRINT</h2>
                        </div>
                        <Hidden mdDown>
                            <Divider orientation="vertical" flexItem/>
                            {menu}
                        </Hidden>
                    </Stack>
                </div>

                <div>
                    {/* Utilise Hidden pour masquer le bouton sur les grands écrans */}
                    <Hidden mdDown>
                        {buttonConfig}
                    </Hidden>

                    <Hidden mdUp>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon/>
                        </IconButton>
                    </Hidden>
                </div>
                <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={handleDrawerClose}
                >
                    {/* Contenu du menu va ici */}
                    <div style={{width: 250, padding: "5px 2px", backgroundColor: "darkslategrey", height: "100%"}}>
                        <Grid container spacing={2} alignItems={"center"} justifyContent={"start"}>
                            <Grid item xs={12}>
                                {buttonConfig}
                            </Grid>
                            <Grid item sm={12}>
                                {menu}
                            </Grid>
                        </Grid>
                    </div>
                </Drawer>
            </div>
        </div>
    );
};

export default HamburgerMenu;
