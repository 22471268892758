import HamburgerMenu from "./HamburgerMenu";
import React, {useEffect, useMemo, useState} from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {getMachineHistories, getMachines} from "../Service/MachineService";
import Box from "@mui/material/Box";
import Machine from "./Machine/Machine";
import socket from "../Service/Socket";
import useMachineStore from "../Store/MachineStore";
import {DragDropContext} from "react-beautiful-dnd";
import _ from "lodash";
import {getTasks, traitementFile, updatePriorityTaskMachine} from "../Service/TaskService";
import {
    Backdrop,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel, Select,
    Snackbar
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import Alert from "@mui/material/Alert";
import TaskConfiguration from "./Machine/TaskConfiguration";
import MenuItem from "@mui/material/MenuItem";
import {PictureAsPdf} from "@mui/icons-material";
import MachinePreviewTasks from "./Machine/MachinePreviewTasks";
import {History} from "./History";
import Utils from "../Utils/Utils";
import CircularProgress from "@mui/material/CircularProgress";

export default function Home() {
    const {machines, setMachines, updateMachine} = useMachineStore()
    const io = socket

    const [openDialogPowerOffMachine, setOpenDialogPowerOffMachine] = useState(false)
    const [openTaskConfiguration, setOpenTaskConfiguration] = useState(false)
    const [openPreview, setOpenPreview] = useState(false)
    const [previewFile, setPreviewFile] = useState(null)
    const [taskConfigurationFile, setTaskConfigurationFile] = useState(null)
    const [taskConfigurationMachine, setTaskConfigurationMachine] = useState(null)
    const [openErrorMessage, setOpenErrorMessage] = useState(false)
    const [machineFilters, setMachineFilters] = useState({
        technique: ""
    })
    const [previewMachine, setPreviewMachine] = useState(null)
    const [openPreviewMachine, setOpenPreviewMachine] = useState(false)
    const [previewTasks, setPreviewTasks] = useState(null)
    const [showBackdrop, setShowBackdrop] = useState(false)


    useEffect(() => {
        getMachines().then(response => {
            if (response.fetchedMachines) {
                setMachines(response.fetchedMachines)
            }
        })

        io.on("updateMachine", (data) => {
            for (const m of data) {
                updateMachine(m)
            }
        })
    }, []);

    const combineTasks = (allTasks, pendingTasks) => [
        ...allTasks.filter(t => +t.status !== 3),
        ...pendingTasks
    ];

    const impressionTechniques = useMemo(() => {
        const techniques = machines.map(m => {
            return {
                value: m.printingTechnique.id,
                label: m.printingTechnique.name
            }
        })

        return _.uniqBy(techniques, "value")
    }, [machines])

    const filteredMachines = useMemo(() => {
        return machines.filter(m => {
            if (machineFilters.technique) {
                return m.printingTechnique.id === machineFilters.technique
            }

            return true
        })
    }, [machines, machineFilters])

    const handleCloseDialogPowerOffMachine = () => {
        setOpenDialogPowerOffMachine(false)
    }

    const onDropFile = async (event, machine) => {
        if (!machine.power) {
            setOpenDialogPowerOffMachine(true)
            return
        }

        const validFiles = await Utils.getAllDroppedFiles(event.dataTransfer.items)

        if (validFiles.length > 0) {
            setTaskConfigurationFile(validFiles)
            setTaskConfigurationMachine(machine)
            setOpenTaskConfiguration(true)
        } else {
            alert('Only PDF and image files are allowed.');
        }
    }

    const onDragEnd = (result) => {
        const {destination, source, draggableId} = result;

        // Si l'élément est déposé en dehors des colonnes
        if (!destination) {
            return;
        }

        // Si l'élément est déposé à la même position
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const sourceMachine = _.find(machines, {id: +source.droppableId});
        const destinationMachine = _.find(machines, {id: +destination.droppableId});

        if (!destinationMachine.power) {
            return
        }

        const sourceTasks = Array.from(sourceMachine.tasks).filter(t => +t.status === 3);
        const destinationTasks = Array.from(destinationMachine.tasks).filter(t => +t.status === 3);

        if (sourceMachine.id === destinationMachine.id) {
            const [movedItem] = sourceTasks.splice(source.index, 1);
            sourceTasks.splice(destination.index, 0, movedItem)
            sourceTasks.map((t, i) => {
                t.priority = i + 1
                return t
            })

            updatePriorityTaskMachine({
                typeDrop: "changePriority",
                targetMachineId: sourceMachine.id,
                orders: sourceTasks.map(data => {
                    return {
                        "taskId": data.id,
                        "priority": data.priority
                    }
                })
            }).then();

            setTimeout(() => {
                updateMachine({
                    ...sourceMachine,
                    tasks: combineTasks(sourceMachine.tasks, sourceTasks)
                })
            }, 300)
        } else {
            const [movedItem] = sourceTasks.splice(source.index, 1);
            destinationTasks.splice(destination.index, 0, movedItem);

            sourceTasks.map((t, i) => {
                t.priority = i + 1
                return t
            })

            destinationTasks.map((t, i) => {
                t.priority = i + 1
                return t
            })

            sourceMachine.tasks = combineTasks(sourceMachine.tasks, sourceTasks);
            destinationMachine.tasks = combineTasks(destinationMachine.tasks, destinationTasks);

            updatePriorityTaskMachine({
                typeDrop: "changeTaskMachine",
                taskId: draggableId,
                targetMachineId: destinationMachine.id,
                orders: destinationTasks.map(data => {
                    return {
                        "taskId": data.id,
                        "priority": data.priority
                    }
                })
            }).then();

            setTimeout(() => {
                updateMachine({
                    ...sourceMachine,
                    tasks: combineTasks(sourceMachine.tasks, sourceTasks)
                })

                updateMachine({
                    ...destinationMachine,
                    tasks: combineTasks(destinationMachine.tasks, destinationTasks)
                })
            }, 300)
        }
    }

    const handleCloseErrorMessage = () => {
        setOpenErrorMessage(false)
    }

    const handleOpenPreviewFile = (task) => {
        if (!task.previewUrl) {
            return
        }
        setPreviewFile(task.previewUrl)
        setOpenPreview(true)
    }

    return (
        <>
            <HamburgerMenu/>

            <Dialog
                open={openDialogPowerOffMachine}
                onClose={handleCloseDialogPowerOffMachine}
            >
                <DialogTitle id="alert-dialog-title" sx={{display: "flex", alignItems: "center", gap: 1}}>
                    Imprimante éteinte
                </DialogTitle>
                <DialogContent>
                    <Alert severity="error">
                        <DialogContentText id="alert-dialog-description">
                            L'imprimante qui va imprimer les fichiers est éteinte.
                        </DialogContentText>
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogPowerOffMachine} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openPreview}
                fullWidth
                onClose={() => {
                    setOpenPreview(false)
                }}
            >
                <DialogContent>
                    <img src={previewFile} style={{width: "100%"}}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenPreview(false)
                    }}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>


            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                open={openErrorMessage}
                onClose={handleCloseErrorMessage}
                autoHideDuration={2000}
            >
                <Alert
                    onClose={handleCloseErrorMessage}
                    severity="error"
                    variant="filled"
                    sx={{width: '100%'}}
                >
                    Une erreur s'est produite pendant l'upload du fichier
                </Alert>
            </Snackbar>

            <Backdrop style={{zIndex: 9999}} open={showBackdrop}>
                <CircularProgress color="inherit"/>
            </Backdrop>

            {openTaskConfiguration && taskConfigurationFile && taskConfigurationFile.length &&
                <TaskConfiguration
                    openTaskConfiguration={openTaskConfiguration}
                    setOpenTaskConfiguration={setOpenTaskConfiguration}
                    files={taskConfigurationFile}
                    machine={taskConfigurationMachine}
                    onErrorUpload={() => {
                        setOpenErrorMessage(true)
                    }}
                    onsubmit={(filesData) => {
                        setShowBackdrop(true)
                        Promise.all(filesData.map(fileData => {
                            const formData = new FormData()
                            formData.append("theFile", fileData.theFile)
                            formData.append("typeDrop", fileData.typeDrop)
                            formData.append("printingTechnique", fileData.printingTechnique)
                            formData.append("printerNumber", fileData.printerNumber)
                            formData.append("numberPrint", fileData.numberPrint)
                            formData.append("pngConvert", fileData.pngConvert)
                            if (fileData.width && fileData.height) {
                                formData.append("width", fileData.width)
                                formData.append("height", fileData.height)
                            }

                            return traitementFile(formData)
                        }))
                            .then()
                            .finally(() => {
                                setOpenTaskConfiguration(false)
                                setShowBackdrop(false)
                            })
                    }}
                />
            }

            {openPreviewMachine &&
                <MachinePreviewTasks
                    machine={previewMachine}
                    tasks={previewTasks}
                    open={openPreviewMachine}
                    setOpen={setOpenPreviewMachine}
                    onLoadNext={() => {
                        const nextPage = Math.ceil(previewTasks.length / 20) + 1
                        if (previewMachine) {
                            getMachineHistories(previewMachine.id, nextPage).then(response => {
                                setPreviewTasks([
                                    ...previewTasks,
                                    ...response.data
                                ])
                            })
                        } else {
                            getTasks(nextPage).then(response => {
                                setPreviewTasks([
                                    ...previewTasks,
                                    ...response.fetchedTasks
                                ])
                            })
                        }
                    }}
                />
            }

            <Grid2 container>
                <Grid2 xs={9}>
                    <Box sx={{px: 4, py: 2, textAlign: "left"}}>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={4}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Technique d'impression</InputLabel>
                                    <Select
                                        size="small"
                                        label="Technique d'impression"
                                        onChange={(e) => {
                                            setMachineFilters({
                                                ...machineFilters,
                                                technique: +e.target.value
                                            })
                                        }}
                                        defaultValue=""
                                    >
                                        <MenuItem value="">Toutes</MenuItem>
                                        {impressionTechniques.map((technique) => (
                                            <MenuItem key={technique.value}
                                                      value={technique.value}>
                                                {technique.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid2>
                            <Grid2>
                                <Button
                                    variant="contained"
                                    startIcon={<PictureAsPdf/>}
                                    color="primary"
                                    onClick={() => {
                                        setOpenPreviewMachine(true)
                                    }}
                                > Impressions encours</Button>
                            </Grid2>
                        </Grid2>
                    </Box>

                    <Box sx={{px: 4}}>
                        <Grid2 container spacing={2}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <>
                                    {filteredMachines.map(machine => (
                                        <Grid2 key={machine.id} xs={3}>
                                            <Machine
                                                machine={machine}
                                                onDropFinish={(event) => {
                                                    onDropFile(event, machine)
                                                }}
                                                onPreviewFileTask={handleOpenPreviewFile}
                                                onPreviewMachineTask={(machine) => {
                                                    setPreviewTasks(null)
                                                    setPreviewMachine(machine)
                                                    setOpenPreviewMachine(true)
                                                }}
                                                onPreviewHistories={(tasks) => {
                                                    setPreviewTasks(tasks)
                                                    setPreviewMachine(machine)
                                                    setOpenPreviewMachine(true)
                                                }}
                                            />
                                        </Grid2>
                                    ))}
                                </>
                            </DragDropContext>
                        </Grid2>
                    </Box>
                </Grid2>
                <Grid2 xs>
                    <History
                        onPreviewFileTask={handleOpenPreviewFile}
                        onPreviewTasks={(tasks) => {
                            setPreviewMachine(null)
                            setPreviewTasks(tasks)
                            setOpenPreviewMachine(true)
                        }}
                    />
                </Grid2>
            </Grid2>
        </>
    )
}
