import React, { useState } from 'react';
import {TextField} from "@mui/material";

const UrlForm = ({ index, handleInputChange, defaultValue }) => {
    const [formData, setFormData] = useState({ url: defaultValue});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);
        handleInputChange(index, updatedFormData);
    };

    return (
        <div style={{marginTop:"20px"}}>
            <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Url"
                rows={4}
                value={formData?.url}
                name="url"
                onChange={handleChange}
            />
        </div>
    );
};

export default UrlForm;