import axios from "axios";

const BASE_URI = process.env.REACT_APP_BASE_URI;

const headersMultiPartForm = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

const headersAppLd = {
    headers: {
        "Content-Type": "application/ld+json",
    },
};

const headersAppJson = {
    headers: {
        "Content-Type": "application/json",
    },
};

export const getMachines = async () => {
    const url = BASE_URI + "/api/machines";
    const datas = {};
    try {
        const {data} = await axios.get(url, datas);
        return {status: true, fetchedMachines: data["hydra:member"]};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        console.log("here error: ", error);
        return {status: false, error: message};
    }
};

export const getMachineAndTaskByTechnique = async (technique: string) => {
    const url = BASE_URI + "/machines/get-machine-and-task-by-technique";
    const datas: any = {technique};

    try {
        const {data} = await axios.post(url, datas);
        return {response: data.response, fetchedMachines: data.machines, tasks: data.tasks};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {status: false, error: message};
    }
};

export const modifyMachine = async (datas: any) => {
    const url = BASE_URI + "/api/machines/save-info/" + datas.id;
    const datasToSend = datas;

    try {
        const {data} = await axios.put(url, datasToSend, headersAppLd);
        return {status: true, fetchedMachines: data["hydra:member"], id: data.id};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {status: false, error: message};
    }
};

export const createMachine = async (datas: object) => {
    const url = BASE_URI + "/api/machines/save-info";
    const datasToSend = datas;

    try {
        const {data} = await axios.post(url, datasToSend, headersAppLd);
        return {status: true, id: data.id};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {status: false, error: message};
    }
};

export const deleteTask = async (id: string) => {
    const url = BASE_URI + "/api/tasks/" + id;
    const datas = {};

    try {
        await axios.delete(url, datas);
        return {status: true};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {status: false, error: message};
    }
};

export const changePropertyMachine = async (datas: any) => {
    const url = BASE_URI + "/machines/change-property";
    const datasToSend = datas;

    try {
        const {data} = await axios.post(url, datasToSend, headersAppLd);
        return {response: data.response};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {status: false, error: message};
    }
};

export const deleteMachine = async (machineId: null | undefined) => {
    const url = BASE_URI + "/machines/delete";
    const datas: {} = {machineId};

    try {
        const {data} = await axios.post(url, datas, headersAppJson);
        return {response: data.response, message: data.message};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {response: "error", error: message};
    }
};

export const patchMachine = (id: number, data: any) => {
    const url = BASE_URI + "/api/machines";

    if (typeof data !== "string") {
        data = JSON.stringify(data)
    }

    let config = {
        method: 'patch',
        url: `${url}/${id}`,
        headers: {
            'Content-Type': 'application/merge-patch+json'
        },
        data: data
    };

    return axios.request(config)
}


export const clearTasks = (id: number) => {
    const url = BASE_URI + `/api/machines/${id}/clear-tasks`;

    let config = {
        method: 'get',
        url: url,
    };

    return axios.request(config)
}


export const transferTasks = (tasks = [], position = "first", machine: { number: string }) => {
    const url = BASE_URI + "/api/machines/transfert-tasks";
    return axios.post(url, {
        tasks,
        position,
        machine,
    }, headersAppJson)
}

export const removeTasks = (tasks = []) => {
    const url = BASE_URI + "/api/machines/remove-tasks";
    return axios.post(url, {
        tasks
    }, headersAppJson)
}

export const getMachineHistories = (id: number, page = 1) => {
    const url = BASE_URI + `/api/machines/${id}/histories?page=${page}`
    let config = {
        method: 'get',
        url: url,
    };
    return axios.request(config)
}
