import axios from "axios";
const BASE_URI = process.env.REACT_APP_BASE_URI;

const headersAppLd = {
    headers: {
        "Content-Type": "application/ld+json",
    },
};

export const deleteTechnique = async (id: string) => {
    const url = BASE_URI + "/api/printing-techniques/" + id;
    const datas = {};

    try {
        await axios.delete(url, datas);
        return { status: true };
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return { status: false, error: message };
    }
};

export const getPrintingTechnique = async () => {
    const url = BASE_URI + "/api/printing-techniques";
    const datas = {};

    try {
        const { data } = await axios.get(url, datas);
        return { status: true, fetchedPrintings: data["hydra:member"] };
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return { status: false, error: message };
    }
};

export const createPrintingTechnique = async (datas: object) => {
    const url = BASE_URI + "/api/printing-techniques/save-info";
    const datasToSend = datas;

    try {
        const { data } = await axios.post(url, datasToSend, headersAppLd);
        return { status: true, id: data.id };
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return { status: false, error: message };
    }
};

export const modifyPrintingTechnique = async (id:string, datas: object) => {
    const url = BASE_URI + "/api/printing-techniques/" + id;
    const datasToSend = datas;

    try {
        const { data } = await axios.put(url, datasToSend, headersAppLd);
        return { status: true, id: data.id };
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return { status: false, error: message };
    }
};
