import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItemButton,
    ListItemIcon, ListItemText
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {green} from "@mui/material/colors";
import {Archive, EditNote, PictureAsPdf, Print, PublishedWithChanges} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import useMachineStore from "../../Store/MachineStore";
import {getMachineHistories, patchMachine} from "../../Service/MachineService";
import ListItem from "@mui/material/ListItem";
import ImageIcon from "@mui/icons-material/Image";
import MachineActions from "./MachineActions";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {TaskItem} from "./TaskItem";
import MachineNote from "./MachineNote";
import Constant from "../../Utils/Constant";
import Tooltip from "@mui/material/Tooltip";
import TaskPreloadProgress from "./TaskPreloadProgress";
import {removeFromHistory, updateTaskPosition} from "../../Service/TaskService";
import IconLoadingButton from "../UI/IconLoadingButton";

export default function Machine({machine, onDropFinish, onPreviewFileTask, onPreviewMachineTask, onPreviewHistories}) {
    const [loading, setLoading] = useState(false)
    const [editNoteMachine, setEditNoteMachine] = useState(null)
    const {updateMachine} = useMachineStore()
    const taskListRef = useRef()
    const [toDeletedTasks, setToDeletedTasks] = useState([])
    const [onLoadingHistories, setOnLoadingHistories] = useState(false)

    useEffect(() => {
        setLoading(machine.loading)
    }, [machine])

    const togglePower = () => {
        updateMachine({
            ...machine,
            loading: true
        })


        patchMachine(machine.id, {
            power: !machine.power
        }).then(response => {
            if (response.data) {
                updateMachine({
                    ...response.data,
                    loading: false
                })
                return
            }

            updateMachine(machine)
        })
    }

    const getTaskHistories = () => {
        setOnLoadingHistories(true)
        setTimeout(() => {
            getMachineHistories(machine.id).then(response => {
                if (response.data) {
                    onPreviewHistories(response.data)
                }
            }).finally(() => {
                setOnLoadingHistories(false)
            })
        }, 1000)
    }

    const getTasks = useCallback((status) => {
        return machine.tasks.filter(task => status.includes(task.status)).sort((a, b) => a.priority - b.priority)
    }, [machine.tasks])

    const getInProgressTasks = useCallback(() => {
        return machine.tasks.filter(task => [Constant.TASK_STATUS_IN_PROGRESS, Constant.TASK_STATUS_PRELOADED, Constant.TASK_STATUS_PRINTING]
            .includes(task.status))
            .sort((a, b) => a.priority - b.priority)
    }, [machine.tasks])

    const handleDrop = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const className = taskListRef.current.className
        taskListRef.current.className = className.replace("drag-over", "")

        onDropFinish(event)
    }

    return (
        <Box sx={{position: "relative"}}>
            {editNoteMachine && editNoteMachine.id === machine.id &&
                <Box sx={{position: "absolute", right: -285, top: 5, zIndex: 7000}}>
                    <MachineNote
                        machine={machine}
                        onSaveFinish={() => {
                            setEditNoteMachine(null)
                        }}
                        onClose={() => {
                            setEditNoteMachine(null)
                        }}
                    />
                </Box>
            }

            <Card sx={{position: "relative"}}>
                <CardHeader
                    sx={{pb: 0}}
                    avatar={
                        <Box sx={{position: 'relative'}}>
                            <Fab
                                aria-label="save"
                                color={machine.power ? "success" : "error"}
                                onClick={togglePower}
                                sx={{boxShadow: "none"}}
                            >
                                <Avatar sx={{background: "transparent"}}>
                                    {machine.number}
                                </Avatar>
                            </Fab>
                            {loading && (
                                <CircularProgress
                                    size={68}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: -6,
                                        left: -6,
                                        zIndex: 1,
                                    }}
                                />
                            )}
                        </Box>
                    }
                    title={<Tooltip title={machine.name}>
                        <Typography
                            sx={{cursor: "pointer", height: 24, overflow: "hidden"}} fontWeight="bold"
                            variant="subtitle2"
                            onClick={() => {
                                onPreviewMachineTask(machine)
                            }}>
                            {machine.name}
                        </Typography>
                    </Tooltip>}
                    subheader={
                        <>
                            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                                <Tooltip title={machine.ipAdress}>
                                    <Typography
                                        fontWeight="bold"
                                        variant="caption"
                                        color="text.secondary"
                                        sx={{cursor: "default"}}
                                    >
                                        <>
                                            IP: {machine.ipAdress.split(".").slice(-1)}
                                        </>
                                    </Typography>
                                </Tooltip>

                                <Tooltip title={"Ajouter une note"}>
                                    <IconButton
                                        onClick={() => {
                                            setEditNoteMachine(machine)
                                        }}
                                        aria-label="settings">
                                        <EditNote/>
                                    </IconButton>
                                </Tooltip>

                                <IconLoadingButton
                                    onClick={getTaskHistories}
                                    aria-label="history"
                                    loading={onLoadingHistories}
                                    icon={<Tooltip title={"Historique des tâches"}>
                                        <Archive fontSize={"small"}/></Tooltip>}
                                />
                            </Stack>
                        </>
                    }
                />

                <CardContent
                    sx={{height: 415}}
                >
                    <Stack
                        sx={{flexGrow: 1, display: "flex", height: "100%"}}
                    >
                        <Box>
                            <MachineActions
                                machine={machine}
                                onScrolltTop={() => {
                                    const element = document.getElementById(`machineTask${machine.id}`);
                                    element.scrollTo({top: 0, behavior: 'smooth'});
                                }}
                                onScrollBottom={() => {
                                    const element = document.getElementById(`machineTask${machine.id}`);
                                    element.scrollTo({top: element.scrollHeight, behavior: 'smooth'});
                                }}
                            />
                        </Box>
                        <Box
                            sx={{height: "100%", overflow: "auto", display: "flex", flexDirection: "column"}}
                            ref={taskListRef}
                            onDrop={handleDrop}
                            onDragOver={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                const className = taskListRef.current.className
                                if (!className.includes("drag-over")) {
                                    taskListRef.current.className = className + " drag-over"
                                }
                            }}
                            onDragLeave={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                const className = taskListRef.current.className
                                taskListRef.current.className = className.replace("drag-over", "")
                            }}
                        >
                            {getInProgressTasks().length > 0 &&
                                <>
                                    <List dense={true} sx={{m: 0, p: 0, background: "#eee"}}>
                                        {getInProgressTasks().map(task => (
                                            <ListItemButton
                                                key={task.id}
                                                sx={{opacity: "1 !important", px: 1}}
                                                // disabled
                                                selected={task.status === Constant.TASK_STATUS_IN_PROGRESS && !machine.preload}
                                            >
                                                <ListItemIcon
                                                    sx={{minWidth: 28}}
                                                >
                                                    {task.fileName.includes("pdf") ?
                                                        <PictureAsPdf fontSize={"small"}/> :
                                                        <ImageIcon fontSize={"small"}/>}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={task.id + "-" + machine.printingTechnique.name}
                                                />

                                                {machine.preload && task.status === Constant.TASK_STATUS_IN_PROGRESS &&
                                                    <ListItemIcon
                                                        size="small"
                                                        sx={{minWidth: 28}}
                                                    >
                                                        <TaskPreloadProgress preloadDuration={machine.preloadDuration}/>
                                                    </ListItemIcon>
                                                }

                                                {task.status === Constant.TASK_STATUS_PRINTING &&
                                                    <ListItemIcon
                                                        size="small"
                                                        sx={{minWidth: 28}}
                                                        color="success"
                                                        className="animate__animated animate__flash animate__infinite"
                                                    >
                                                        <Tooltip title={"Fichier précharger"}>
                                                            <Print color="success"/>
                                                        </Tooltip>
                                                    </ListItemIcon>
                                                }

                                                {task.status === Constant.TASK_STATUS_PRELOADED &&
                                                    <ListItemIcon
                                                        size="small"
                                                        sx={{minWidth: 28}}
                                                        color="success"
                                                    >
                                                        <Tooltip title={"Fichier précharger"}>
                                                            <PublishedWithChanges fontSize="small" color="success"/>
                                                        </Tooltip>
                                                    </ListItemIcon>
                                                }
                                            </ListItemButton>
                                        ))}
                                    </List>
                                    <Divider/>
                                </>
                            }

                            <Droppable
                                key={machine.id.toString()} droppableId={machine.id.toString()}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={{
                                            minHeight: "100px",
                                            backgroundColor: snapshot.isDraggingOver ? '#eeeeee' : '',
                                            // padding: 5,
                                            borderRadis: 15,
                                            overflow: 'auto',
                                            height: "100%"
                                        }}
                                        id={"machineTask" + machine.id}
                                        {...provided.droppableProps}
                                    >
                                        <span style={{display: "none"}}>{provided.placeholder}</span>
                                        <List dense={true}
                                              sx={{px: 0}}
                                        >
                                            {getTasks([Constant.TASK_STATUS_WAITING]).map((task, index) => (
                                                <Draggable key={task.id} draggableId={task.id.toString()}
                                                           index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="list-item"
                                                        >
                                                            <ListItem
                                                                disablePadding
                                                                sx={{px: 0}}
                                                            >
                                                                <TaskItem
                                                                    task={task}
                                                                    onPreviewFileTask={onPreviewFileTask}
                                                                    toDeletedTasks={toDeletedTasks}
                                                                    onRemoveTask={() => {
                                                                        const userConfirmed = window.confirm('Voulez-vous vraiment supprimer ?');
                                                                        if (userConfirmed === true) {
                                                                            setToDeletedTasks([task.id])
                                                                            removeFromHistory({
                                                                                taskId: task.id,
                                                                                status: 4,
                                                                                folder: "history"
                                                                            }).then()
                                                                        }
                                                                    }}
                                                                    onTaskSended={(_task, _machine, position) => {
                                                                        updateTaskPosition(_task, position, _machine).then(() => {
                                                                        })
                                                                    }}
                                                                    onSetFirstPosition={() => {
                                                                        updateTaskPosition(task, "first", machine).then(() => {
                                                                        })
                                                                    }}
                                                                />
                                                            </ListItem>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </List>
                                    </div>
                                )}
                            </Droppable>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    )
}
