import React, {useEffect, useMemo, useState} from "react";
import useMachineStore from "../../Store/MachineStore";
import _ from "lodash";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import MenuItem from "@mui/material/MenuItem";
import {Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import {TaskItem} from "./TaskItem";
import {removeFromHistory, updateTaskPosition} from "../../Service/TaskService";
import {removeTasks, transferTasks} from "../../Service/MachineService";
import {LazyLoadImage} from "react-lazy-load-image-component";
import CircularProgress from "@mui/material/CircularProgress";
import {InView} from "react-intersection-observer";

export default function MachinePreviewTasks({open, setOpen, machine, onLoadNext, tasks = null}) {
    const {machines} = useMachineStore()
    const [currentMachine, setCurrentMachine] = useState(machine)
    const [selectedTasks, setSelectedTasks] = useState([])
    const [toDeletedTasks, setToDeleteTasks] = useState([])

    useEffect(() => {
        setCurrentMachine(machine)
    }, [machine]);

    const toggleSelectTask = (task) => {
        if (selectedTasks.includes(task.id)) {
            setSelectedTasks([
                ...selectedTasks.filter(tId => {
                    return tId !== task.id
                })
            ])
        } else {
            setSelectedTasks([
                ...selectedTasks,
                task.id
            ])
        }
    }

    const currentTasks = useMemo(() => {
        if (open) {
            if (tasks) {
                return tasks
            }

            if (currentMachine) {
                return _.cloneDeep(_.find(machines, {id: currentMachine.id}).tasks).filter(task => task.previewUrl).map(t => {
                    return {
                        ...t,
                        machine: {
                            id: currentMachine.id,
                            name: currentMachine.name,
                            number: currentMachine.number,
                            printingTechnique: currentMachine.printingTechnique
                        }
                    }
                })
            } else {
                let _tasks = []
                for (const _machine of machines) {
                    _tasks = _.union(_tasks, _machine.tasks.filter(task => task.previewUrl).map(t => {
                        return {
                            ...t,
                            machine: {
                                id: _machine.id,
                                name: _machine.name,
                                number: _machine.number,
                                printingTechnique: _machine.printingTechnique
                            }
                        }
                    }))
                }

                return _tasks
            }
        }

        return []
    }, [currentMachine, open, machines, tasks])

    const handleTaskSend = (_task, _machine, position = "last") => {
        if (selectedTasks.length > 0 && selectedTasks.includes(_task.id)) {
            transferTasks(selectedTasks, position, _machine).then(() => {
                setOpen(false)
            })
        } else {
            updateTaskPosition(_task, position, _machine).then(() => {
                setOpen(false)
            })
        }
    }

    const handleRemoveTask = (task) => {
        const userConfirmed = window.confirm('Voulez-vous vraiment supprimer ?');
        if (userConfirmed === true) {
            if (selectedTasks.length > 1) {
                setToDeleteTasks(selectedTasks)
                removeTasks(selectedTasks).then()
            } else {
                setToDeleteTasks([task.id])
                removeFromHistory({
                    taskId: task.id,
                    status: 4,
                    folder: "history"
                }).then()
            }
        }
    }

    return (
        <>
            <Dialog
                fullWidth
                open={open}
                onClose={setOpen}
                maxWidth="lg"
            >
                <DialogTitle>Aperçu {machine ? (" des fichiers de la machine " + machine.name) : " de tous les fichiers"}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        setOpen(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close/>
                </IconButton>
                <DialogContent>
                    <Box>
                        {tasks === null &&
                            <Grid2 container spacing={2} sx={{mb: 1}}>
                                <Grid2 xs={3}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Machines</InputLabel>
                                        <Select
                                            size="small"
                                            label="Machines"
                                            onChange={(e) => {
                                                if (!e.target.value) {
                                                    setCurrentMachine(null)
                                                }

                                                setCurrentMachine(_.find(machines, {id: +e.target.value}))
                                            }}
                                            defaultValue={machine?.id}
                                        >
                                            <MenuItem value="">Toutes</MenuItem>
                                            {machines.map((_machine) => (
                                                <MenuItem key={_machine.id}
                                                          value={_machine.id}>
                                                    {_machine.number} - {_machine.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid2>
                            </Grid2>
                        }

                        <Grid2 container spacing={2}>
                            {currentTasks.length === 0 &&
                                <Alert sx={{mt: 2, textAlign: "center", width: "100%"}} severity="info">Aucune tâche en
                                    cours</Alert>}

                            {currentTasks.map(task => (
                                <Grid2 key={task.id} xs={3}>
                                    <Box
                                        className={"preview-task " + (selectedTasks.includes(task.id) ? "active" : "")}
                                        sx={{borderRadius: 2}}
                                        onClick={() => {
                                            toggleSelectTask(task)
                                        }}
                                    >
                                        <Box sx={{
                                            height: 250,
                                            borderRadius: 2,
                                            background: "#eeee",
                                            position: "relative",
                                            overflow: "hidden"
                                        }}>
                                            <LazyLoadImage
                                                alt={task.name}
                                                effect="blur"
                                                height="100%"
                                                width="100%"
                                                style={{objectFit: "contain"}}
                                                src={task.previewUrl}
                                            />
                                        </Box>
                                        <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                                            <TaskItem
                                                showFileIcon={false}
                                                showMachine={!Boolean(currentMachine)}
                                                task={task}
                                                toDeletedTasks={toDeletedTasks}
                                                onSetFirstPosition={() => {
                                                    setOpen(false)
                                                    handleTaskSend(task, machine, "first")
                                                }}
                                                onPreviewFileTask={() => {
                                                }}
                                                onTaskSended={handleTaskSend}
                                                onRemoveTask={handleRemoveTask}
                                            />
                                        </Stack>
                                    </Box>
                                </Grid2>
                            ))}
                        </Grid2>


                        {tasks !== null && (tasks.length % 20 === 0) &&
                            <InView onChange={(inView, entry) => {
                                if (inView) {
                                    if (onLoadNext) {
                                        onLoadNext()
                                    }
                                }
                            }}>
                                <Box sx={{textAlign: "center", mt: 2}}>
                                    <CircularProgress/>
                                </Box>
                            </InView>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpen(false)
                    }}>Fermer</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}