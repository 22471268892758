import axios from "axios";
import {changePropertyMachine} from "./MachineService";

const BASE_URI = process.env.REACT_APP_BASE_URI;

const headersMultiPartForm = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

const headersAppJson = {
    headers: {
        "Content-Type": "application/json",
    },
};

const headersAppLd = {
    headers: {
        "Content-Type": "application/ld+json",
    },
};

export const deleteTask = async (id: string) => {
    const url = BASE_URI + "/api/tasks/" + id;
    const datas = {};

    try {
        const {data} = await axios.delete(url, datas);
        return {response: data.response};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {status: false, error: message};
    }
};

export const removeFromHistory = async (datas: any) => {
    const url = BASE_URI + "/tasks/remove-from-history";

    try {
        const {data} = await axios.put(url, datas);
        return {response: data.response};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        return {response: "error", error: message};
    }
};

export const traitementFile = async (fileData: any) => {
    try {

        const url = BASE_URI + "/task/traitement-file";
        // Make the POST request and wait for the response
        const {data} = await axios.post(url, fileData, headersMultiPartForm);
        if (data.response === "success") {
            return {response: data.response};
        }
    } catch (error: any) {
        console.error("Error traitement file:", error);
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {response: "error", error: message};
        //throw error;
    }
};

export const updatePriorityTaskMachine = async (datas: any) => {
    const url = BASE_URI + "/tasks/update-priority";

    try {
        const {data} = await axios.post(url, datas, headersAppJson);
        return {response: data.response};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {response: "error", error: message};
    }
}

export const getTasks = async (page = 1) => {
    const url = BASE_URI + `/api/get-finished-tasks?page=${page}`;
    const datas = {};

    try {
        const {data} = await axios.get(url, datas);
        return {response: "success", fetchedTasks: data["hydra:member"]};

    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {response: "error", error: message};
    }
};


export const deleteQueuesMachine = async (datas: any) => {
    const url = BASE_URI + "/tasks/delete-queues-machine";

    try {
        const {data} = await axios.post(url, datas, headersAppJson);
        return {response: data.response};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {response: "error", error: message};
    }
};

export const MoveQueuesMachine = async (datas: any) => {
    const url = BASE_URI + "/tasks/move-queues-machine";
    try {
        const {data} = await axios.post(url, datas, headersAppJson);
        return {response: data.response};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {response: "error", error: message};
    }
};

export const traitementFileWithUrl = async (datas: object) => {
    const url = BASE_URI + "/api/tasks";
    const datasToSend = datas;

    try {
        const {data} = await axios.post(url, datasToSend, headersAppLd);
        return {status: true, datas: data};
    } catch (error: any) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return {status: false, error: message};
    }
};

export const updateTaskPosition = (task: any, position = "first", machine: { number: string } | undefined) => {
    const formData = new FormData();
    formData.append("typeDrop", "rePrint");
    formData.append("taskId", task.id);
    formData.append("queue", position);
    formData.append("sameMachine", machine ? "no" : "yes");
    if (machine) {
        formData.append("printerNumber", machine.number);
    }
    return traitementFile(formData);
}

export const printFirstTask = (machine: any) => {
    return changePropertyMachine({machineId: machine.id, action: "SET_MANUAL_PRINTING"})
}

export const formatTaskName = (task: any) => {

    return task.fileName ? task.fileName.split("-").slice(0, -1).join("-") : "-"
}

export const previewFile = (file: any) => {
    const data = new FormData();
    data.append('file', file)

    const url = BASE_URI + "/api/preview-file";
    // Make the POST request and wait for the response
    return axios.post(url, data, headersMultiPartForm);
}