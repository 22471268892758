import React, {useEffect, useState} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
    Button,
    Divider,
    FormControl,
    Grid, InputAdornment,
    InputLabel,
    Select, Stack,
    TextField, Typography,
} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import DialogContentText from "@mui/material/DialogContentText";
import Chip from "@mui/material/Chip";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {blue} from '@mui/material/colors';
import Fab from '@mui/material/Fab';

import {createMachine, deleteMachine, getMachines, modifyMachine} from "../Service/MachineService";
import {
    createPrintingTechnique,
    getPrintingTechnique, modifyPrintingTechnique,
} from "../Service/PrintingTechnique";
import Utils from "../Utils/Utils";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Popup from "./Popup";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {createRecipe, getRecipes, modifyRecipe} from "../Service/RecipeService";
import {Timer} from "@mui/icons-material";

function createData(id: number, nom: string) {
    return {id, nom};
}

const rows = [createData(1, "DTF"), createData(2, "Technique ZT")];

export default function Configuration() {
    const [openDialogTechnique, setOpenTechnique] = useState(false);
    const [openDialogImprimante, setOpenDialogImprimante] = useState(false);
    const [openDialogDeleteImprimante, setOpenDialogDeleteImprimante] = useState(false);
    const [openDialogRecipe, setOpenDialogRecipe] = useState(false);

    const [technique, setTechnique] = useState("");
    const [machines, setMachines] = useState([]);
    const [recipes, setRecipes] = useState([]);
    const [printingTechniques, setPrintingTechniques] = useState([]);
    const [loadImprimante, setLoadImprimante] = useState(false);
    const [loadTechnique, setLoadTechnique] = useState(false);
    const [loadRecipe, setLoadRecipe] = useState(false);
    const [errMsg, setErrMsg] = useState(null);

    const techniqueData = {id: null, name: ""};
    var machineData = {
        id: null,
        number: "",
        name: "",
        model: "",
        printingTechnique: "",
        ipAdress: "",
        defaultPrinting: false,
        preload: false,
        preloadDuration: 60,
        preloadResetDuration: 60,
        defaultLocationFile: "",
        innactivityTimePrinting: 180
    };
    const [activeImprimante, setActiveImprimante] = useState({
        ...machineData,
        deleted: true
    });
    var recipeData = {
        id: null,
        name: "",
        printingTechnique: "",
    };
    const [activeRecipe, setActiveRecipe] = useState({
        ...recipeData,
        deleted: true
    });
    const [techniqueFormData, setTechniqueFormData] = useState(techniqueData);
    const [recipeFormData, setRecipeFormData] = useState(recipeData);
    const [machineFormData, setMachineFormData] = useState(machineData);
    const [freeNumero, setFreeNumero] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const allNumeros = Array.from({length: 200}, (_, index) => {
        const numero = (index + 1).toString().padStart(2, "0");
        return numero;
    });

    const getAllMachine = async () => {
        var {fetchedMachines} = await getMachines();
        var usedNums = fetchedMachines.map((item: any) => item.number);

        // Filtrer les numéros qui ne sont pas présents dans numeros2
        const freeNums: any = allNumeros.filter(
            (numero) => !usedNums.includes(numero)
        );

        // Utilisation des numéros non communs
        setFreeNumero(freeNums);
        setMachines(fetchedMachines);
    };

    const getAllRecipe = async () => {
        var {response, fetchedRecipes} = await getRecipes();
        if (response === "success") {
            setRecipes(fetchedRecipes);
        }
    };

    const getAllPrintingTechnique = async () => {
        var {fetchedPrintings} = await getPrintingTechnique();
        setPrintingTechniques(fetchedPrintings);
    };

    useEffect(() => {
        getAllMachine();
        getAllPrintingTechnique();
        getAllRecipe();
    }, []);

    const handleClickOpenTechnique = (technique: any = null) => {
        if (technique) {
            setTechniqueFormData({...techniqueFormData, id: technique.id, name: technique.name});
        } else {
            setTechniqueFormData({...techniqueFormData, id: null});
        }
        setOpenTechnique(true);
    };

    const handleClickOpenRecipe = (recipe: any = null) => {
        if (recipe) {
            setRecipeFormData({
                ...recipeFormData,
                id: recipe.id,
                name: recipe.name,
                printingTechnique: recipe.printingTechnique["@id"]
            });
        } else {
            setRecipeFormData({...recipeFormData, id: null});
        }
        setOpenDialogRecipe(true);
    };

    const handleCloseTechnique = () => {
        setOpenTechnique(false);
    };

    const handleClickOpenDialogDeleteImprimante = async (machine: any) => {
        setActiveImprimante({
            ...activeImprimante,
            id: machine.id,
            number: machine.number,
            name: machine.name,
            model: "",
            printingTechnique: machine.printingTechnique["@id"],
            ipAdress: machine.ipAdress,
            defaultPrinting: machine.defaultPrinting,
            defaultLocationFile: machine.defaultLocationFile,
            innactivityTimePrinting: machine.innactivityTimePrinting,
            deleted: true
        });
        setErrMsg(null);
        setOpenDialogDeleteImprimante(true);
    };
    const handleCloseDialogDeleteImprimante = () => {
        setOpenDialogDeleteImprimante(false);
    };

    const handleClickDialogImprimante = (machine: any) => {

        if (machine) {
            //delete machine.printingTechnique;
            delete machine.tasks;
            setMachineFormData({
                ...machineFormData,
                id: machine.id,
                number: machine.number,
                name: machine.name,
                model: "",
                defaultPrinting: machine.defaultPrinting,
                defaultLocationFile: machine.defaultLocationFile,
                innactivityTimePrinting: machine.innactivityTimePrinting,
                printingTechnique: machine.printingTechnique["@id"],
                ipAdress: machine.ipAdress,
                preload: machine.preload,
                preloadDuration: machine.preloadDuration,
                preloadResetDuration: machine.preloadResetDuration,
            });

        } else {
            setMachineFormData(machineData);
        }
        setLoadImprimante(false);
        setOpenDialogImprimante(true);
    };
    const handleCloseDialogImprimante = () => {
        setOpenDialogImprimante(false);
    };
    const handleChangeTechnique = (event: any) => {
        setTechniqueFormData({
            ...techniqueFormData,
            [event.target.name]: event.target.value as string,
        });
    };

    const handleChangeRecipe = (event: any) => {
        setRecipeFormData({
            ...recipeFormData,
            [event.target.name]: event.target.value as string,
        });
    };

    const handleChangeMachine = (event: any) => {
        setMachineFormData({
            ...machineFormData,
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
        });
    };

    const handleSaveTechnique = async () => {
        let verif = Utils.verifyData(techniqueFormData, "printing"),
            result;
        setLoadTechnique(true);
        if (verif?.type === "error") {
        } else {
            if (techniqueFormData.id !== null) {
                result = await modifyPrintingTechnique(techniqueFormData.id, techniqueFormData);
            } else {
                result = await createPrintingTechnique(techniqueFormData);
            }

            if (result.id) {
                getAllPrintingTechnique();
                setOpenTechnique(false);
                setTechniqueFormData(techniqueData);
                setLoadTechnique(false);
            }
        }
    };

    const handleSaveMachine = async (action = "add") => {
        var verif: any, result, datas: any = machineFormData;
        if (action !== "delete") {
            verif = Utils.verifyData(machineFormData, "machine");
        }

        if (verif?.type === "error") {
            alert(verif.message);
        } else {
            setLoadImprimante(true);
            if (datas.innactivityTimePrinting === "") {
                datas["innactivityTimePrinting"] = 180;
            }
            if (action === "delete") {
                datas = activeImprimante;
            }

            if (datas.id !== null) {
                result = await modifyMachine(datas);
            } else {
                result = await createMachine(datas);
            }

            if (result.id) {
                getAllMachine();
                setOpenDialogImprimante(false);
                setOpenDialogDeleteImprimante(false);
                setMachineFormData(machineData);
                setLoadImprimante(false);
            }
        }
    };

    const handleSaveRecipe = async (action = "add") => {
        var verif: any,
            result,
            datas: any = recipeFormData;
        if (action !== "delete") {
            verif = Utils.verifyData(recipeFormData, "recipe");
        }

        if (verif?.type === "error") {
            alert(verif.message);
        } else {
            setLoadRecipe(true);
            if (action === "delete") {
                datas = activeRecipe;
            }

            if (datas.id !== null) {
                result = await modifyRecipe(datas.id, datas);
            } else {
                result = await createRecipe(datas);
            }

            if (result.id) {
                getAllRecipe();
                setOpenDialogRecipe(false);
                setRecipeFormData(recipeData);
                setLoadRecipe(false);
            }
        }
    };

    const handleOpenDialogRecipe = () => {
        setOpenDialogRecipe(true);
    }

    const handleCloseRecipe = () => {
        setLoadRecipe(false);
        setRecipeFormData(recipeData);
        setOpenDialogRecipe(false);
    }

    const dialogActionRecipe = <>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box>
                <Button onClick={handleCloseRecipe}>
                    Fermer
                </Button>
            </Box>
            <Box sx={{m: 1, position: 'relative'}}>
                <Button
                    variant="contained"
                    disabled={loadRecipe}
                    onClick={() => handleSaveRecipe()}
                >
                    Enregistrer
                </Button>
                {loadRecipe && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: blue[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
        </Box>
    </>;

    const dialogContentTechnique = <div style={{width: "580px"}}>
        <Grid container columns={12}>
            <Grid item xs={12}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="nom_tech_impression"
                    label="Nom"
                    name="name"
                    onChange={handleChangeTechnique}
                    value={techniqueFormData.name}
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </Grid>
        </Grid>
    </div>;

    const dialogActionTechnique = <>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box>
                <Button onClick={handleCloseTechnique}>
                    Fermer
                </Button>
            </Box>
            <Box sx={{m: 1, position: 'relative'}}>
                <Button
                    variant="contained"
                    disabled={loadImprimante}
                    onClick={handleSaveTechnique}
                >
                    Enregistrer
                </Button>
                {loadTechnique && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: blue[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
        </Box>
    </>;

    const dialogContentRecipe = <div style={{width: "580px"}}>
        <Grid container columns={12} spacing={2}>
            <Grid item xs={12}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="recipe"
                    label="Nom"
                    name="name"
                    onChange={handleChangeRecipe}
                    value={recipeFormData.name}
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Téchnique d'impression
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={recipeFormData.printingTechnique}
                        name="printingTechnique"
                        label="Téchnique d'impression"
                        onChange={handleChangeRecipe}
                    >
                        {printingTechniques.map(
                            (item: any, index) => (
                                <MenuItem
                                    key={index}
                                    value={item["@id"]}
                                >
                                    {item.name}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    </div>;

    const handleDeleteMachine = async () => {
        const result = await deleteMachine(activeImprimante?.id);
        console.log("Result: ", result);
        if (result.response === "error") {
            //alert(result.message);
            setErrMsg(result.message);
        } else {
            getAllMachine();
            setErrMsg(null);
            setOpenDialogDeleteImprimante(false);
        }
    }

    const dialogActionDeleteImprimante = <>
        <Button onClick={handleCloseDialogDeleteImprimante}>Fermer</Button>
        <Button onClick={() => handleDeleteMachine()}>Enregistrer</Button>
    </>;

    const dialogContentDeleteImprimante = <div style={{width: "580px"}}>
        <DialogContentText>
            Suppréssion de l'imprimante {activeImprimante?.name}.
        </DialogContentText>
        <Typography style={{color: "red"}}>{errMsg ? "ERREUR: " + " " + errMsg : null} </Typography>

    </div>;

    const dialogActionImprimante = <>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box>
                <Button onClick={handleCloseDialogImprimante}>
                    Fermer
                </Button>
            </Box>
            <Box sx={{m: 1, position: 'relative'}}>
                <Button
                    variant="contained"
                    disabled={loadImprimante}
                    onClick={() => handleSaveMachine("modif")}
                >
                    Enregistrer
                </Button>
                {loadImprimante && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: blue[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
        </Box>
    </>;

    const dialogContentImprimante = <>
        <Grid container spacing={2}>

            <Grid item xs={6}>
                {machineFormData.id !== null
                    ? (
                        <Stack direction="column" alignItems="flex-start" spacing={0.5} sx={{width: '100%'}}>
                            <Typography>
                                <span>NUMERO</span>
                            </Typography>
                            <Stack><span className={"text-danger fw-bold"} style={{
                                color: "red",
                                fontWeight: "600"
                            }}>{machineFormData.number}</span></Stack>
                        </Stack>
                    ) :
                    (
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                                Numéro
                            </InputLabel>
                            <Select
                                id="demo-simple-select"
                                value={machineFormData.number}
                                name="number"
                                label="Numéro"
                                onChange={handleChangeMachine}
                            >
                                {freeNumero.map((item: any, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

            </Grid>

            <Grid item xs={12}>
                <TextField
                    required
                    id="outlined-required"
                    label="Nom"
                    value={machineFormData.name}
                    name="name"
                    onChange={handleChangeMachine}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Téchnique d'impression
                    </InputLabel>
                    <Select
                        value={machineFormData.printingTechnique}
                        name="printingTechnique"
                        label="Téchnique d'impression"
                        onChange={handleChangeMachine}
                    >
                        {printingTechniques.map(
                            (item: any, index) => (
                                <MenuItem
                                    key={index}
                                    value={item["@id"]}
                                >
                                    {item.name}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    label="Adresse IP de l'imprimante"
                    value={machineFormData.ipAdress}
                    name="ipAdress"
                    onChange={handleChangeMachine}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} sx={{mt: 0.5}}>
                    <Grid item xs="auto">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>
                                }
                                checked={machineFormData.preload}
                                onChange={handleChangeMachine}
                                label="Préchargement"
                                name="preload"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs="auto">
                        <TextField
                            label="Préchargement d'un fichier"
                            sx={{width: 'auto'}}
                            disabled={!machineFormData.preload}
                            onChange={handleChangeMachine}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Timer/></InputAdornment>,
                                endAdornment: <InputAdornment position="end">s</InputAdornment>
                            }}
                            value={machineFormData.preloadDuration}
                            name="preloadDuration"
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <TextField
                            label="Réinitialisation"
                            sx={{width: 'auto'}}
                            disabled={!machineFormData.preload}
                            onChange={handleChangeMachine}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Timer/></InputAdornment>,
                                endAdornment: <InputAdornment position="end">mn</InputAdornment>
                            }}
                            value={machineFormData.preloadResetDuration}
                            name="preloadResetDuration"
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel required control={
                        <Checkbox sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>}
                                      checked={machineFormData.defaultPrinting}
                                      onChange={handleChangeMachine}
                                      label="Impression par défaut"
                                      name={"defaultPrinting"}
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    required
                    id="outlined-required"
                    label="Temps d'inactivité pour l'impression par défaut"
                    value={machineFormData.innactivityTimePrinting}
                    name="innactivityTimePrinting"
                    onChange={handleChangeMachine}
                    type={"number"}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    required
                    id="outlined-required"
                    label="Adresse du fichier à imprimer par défaut"
                    value={machineFormData.defaultLocationFile}
                    name="defaultLocationFile"
                    onChange={handleChangeMachine}
                    fullWidth
                    helperText="DTF/dragon-essai-couleur-OK.png"
                />
            </Grid>
        </Grid>
    </>;

    const getUndeletedMachine = (machine: any) => {

        if (machine.deleted !== true) {

            const res =
                <TableRow
                    key={machine.id}
                    sx={{
                        "&:last-child td, &:last-child th": {
                            border: 0,
                        },
                    }}
                >
                    <TableCell component="th" scope="row">
                        {machine.number}
                    </TableCell>
                    <TableCell align="right">
                        <Chip
                            label={machine.name}
                            variant="outlined"
                        />
                    </TableCell>
                    <TableCell align="right">
                        <Chip
                            label={
                                machine.printingTechnique?.name
                            }
                        />
                    </TableCell>
                    <TableCell align="right">
                        <Chip label={machine.ipAdress}/>
                    </TableCell>
                    <TableCell align="right">
                        <Chip label={machine.defaultPrinting === true ? "OUI" : "NON"}
                              color={machine.defaultPrinting ? "success" : "default"}/>
                    </TableCell>
                    <TableCell align="right">
                        {machine.defaultLocationFile && (
                            <Chip label={machine.defaultLocationFile}/>
                        )}
                    </TableCell>
                    <TableCell align="right">
                        <Chip label={machine.innactivityTimePrinting ?? "0"}
                              color={machine.innactivityTimePrinting ? "success" : "default"}/>
                    </TableCell>
                    <TableCell align="right">
                        <Fab size="small" color="primary" aria-label="edit"
                             onClick={() => handleClickDialogImprimante(machine)}>
                            <EditIcon/>
                        </Fab>
                        <Fab size="small" color="error" aria-label="edit"
                             onClick={() => handleClickOpenDialogDeleteImprimante(machine)}>
                            <DeleteIcon/>
                        </Fab>
                    </TableCell>
                </TableRow>;

            return res;
        }
    }

    return (
        <>
            <div style={{textAlign: "left"}}>
                <div>
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon/>}
                        onClick={() => handleClickOpenTechnique(null)}
                        style={{marginBottom: "5px"}}
                    >
                        {" "}
                        Ajouter un téchnique d'impressions{" "}
                    </Button>

                    <br/>
                    <Button style={{marginRight: "5px"}}
                            variant="outlined"
                            startIcon={<AddIcon/>}
                            onClick={() => handleClickDialogImprimante(null)}
                    >
                        {" "}
                        Ajouter un imprimante{" "}
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon/>}
                        onClick={() => handleOpenDialogRecipe()}
                    >
                        Ajouter une recette
                    </Button>
                </div>
                <div style={{textAlign: "left", margin: "10px"}}>
                    <p>Liste des téchnique d'impressions</p>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID </TableCell>
                                <TableCell align="right">Nom</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {printingTechniques.map((technique: any) => (
                                <TableRow
                                    key={technique.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {technique.id}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Chip label={technique.name}/>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Fab size="small" color="primary" aria-label="edit"
                                             onClick={(e: any) => handleClickOpenTechnique(technique)}>
                                            <EditIcon/>
                                        </Fab>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Divider/>

                <div style={{textAlign: "left", margin: "10px"}}>
                    <p>Liste des recettes</p>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID </TableCell>
                                <TableCell align="right">Nom</TableCell>
                                <TableCell align="right">Technique d'impression</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recipes.map((recipe: any) => (
                                <TableRow
                                    key={recipe.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {recipe.id}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Chip label={recipe.name}/>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Chip label={recipe.printingTechnique?.name}/>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Fab size="small" color="primary" aria-label="edit"
                                             onClick={(e: any) => handleClickOpenRecipe(recipe)}>
                                            <EditIcon/>
                                        </Fab>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Divider/>

                <div style={{textAlign: "left", margin: "10px"}}>
                    <p>Liste des imprimantes</p>
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Numéro </TableCell>
                                <TableCell align="right">Nom</TableCell>
                                <TableCell align="right">Téchnique</TableCell>
                                <TableCell align="right">Adresse IP</TableCell>
                                <TableCell align="right">Impressions par Défaut</TableCell>
                                <TableCell align="right">Adresse fichier</TableCell>
                                <TableCell align="right">Délai d'inactivité</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {machines.map((machine: any, index: number) => (
                                getUndeletedMachine(machine)
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <Popup
                open={openDialogTechnique}
                onDialogClose={handleCloseTechnique}
                title={"Ajout / Modification"}
                dialogActions={dialogActionTechnique}
                children={dialogContentTechnique}/>

            <Popup open={openDialogImprimante}
                   children={dialogContentImprimante}
                   dialogActions={dialogActionImprimante}
                   title={"Ajout / Modification"}
                   onDialogClose={handleCloseDialogImprimante}/>

            <Popup open={openDialogDeleteImprimante}
                   children={dialogContentDeleteImprimante}
                   dialogActions={dialogActionDeleteImprimante}
                   title={"Suppression"}
                   onDialogClose={handleCloseDialogDeleteImprimante}/>

            <Popup open={openDialogRecipe}
                   children={dialogContentRecipe}
                   dialogActions={dialogActionRecipe}
                   title={"Ajout / Modification"}
                   onDialogClose={handleCloseRecipe}/>

        </>
    );
}
