import React, {useEffect, useRef, useState} from 'react';
import {
    Button, Container,
    FormControl,
    IconButton,
    InputLabel,
    Select, Switch,
    TextField,
    Typography,
} from "@mui/material";
import HamburgerMenu from "./HamburgerMenu";
import AddIcon from "@mui/icons-material/Add";
import imagesRxjs from "../Service/imagesRxjs";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Stack from "@mui/material/Stack";
import Utils from "../Utils/Utils";
import {imbricateImage} from "../Service/ImageService";
import {blue} from "@mui/material/colors";
import Grid from '@mui/material/Grid';
import {traitementFileWithUrl} from "../Service/TaskService";
import useManageImage from "../Hooks/CustomHooks";
import Popup from "./Popup";
import {getMachines} from "../Service/MachineService";
import PrintIcon from "@mui/icons-material/Print";
import {getPrintingTechnique} from "../Service/PrintingTechnique";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";
import TaskConfiguration from "./Machine/TaskConfiguration";

const textRandom = "random";
export default function (props) {
    const [images, setImages] = useState([]);
    const [statusConfig, setStatusConfig] = useState(true);
    const [statusResNesting, setStatusResNested] = useState(false);
    const [previewFiles, setPreviewFiles] = useState([]);
    const [printingTechniqueFormData, setPrintingTechniqueFormData] = useState({printingTechnique: ""});
    const [activeTechnique, setActiveTechnique] = useState(null);
    const [printingTechniques, setPrintingTechniques] = useState([]);
    const [mirror, setMirror] = useState(false);
    const [processNesting, setProcessNesting] = useState(false);
    const [errMsg, setErrMsg] = useState(null);

    const [configuredWidth, setConfiguredWidth] = useState(0);
    const [configuredHeight, setConfiguredHeight] = useState(0);
    const [autoHeight, setAutoHeight] = useState(false)
    const [hasNoTechnique, setHasNoTechnique] = useState(false);
    const [printing, setPrinting] = useState(false);
    const [openTaskConfiguration, setOpenTaskConfiguration] = useState(false)

    const [machines, setMachines] = useState([]);
    const aleatoireOpt = [{value: textRandom, label: "Aléatoire"}];
    const [optionsMachine, setOptionsMachine] = useState(aleatoireOpt);

    const {
        openDialogAddImage,
        setOpenDialogAddImage,
        setActivePreviewImageDatas,
        setActiveFileDatas,
        handleCloseAddImage,
        handleOpenDialogAddImage,
        dialogAddImageAction,
        dialogContentAddImage,
        setAction,
        droppedFiles,
        setDroppedFiles,
    } = useManageImage();

    useEffect(() => {
        getAllMachine();
        getAllPrintingTechnique();
    }, []);

    useEffect(() => {
        if (droppedFiles.length) {
            setOpenTaskConfiguration(droppedFiles.length > 0)
        }
    }, [droppedFiles]);

    useEffect(() => {
        //##Listen mission obj if it changed
        const imagesObservable = imagesRxjs.getImagesObservable();
        const subscriptionImages = imagesObservable.subscribe((datas) => {
            setImages(datas);
        });

        return () => {
            subscriptionImages.unsubscribe();
        };
    }, []);

    const getAllMachine = async () => {
        var {status, fetchedMachines} = await getMachines();
        if (status) {
            setMachines(fetchedMachines);
            var optsMachine = optionsMachine;
            var formatedOptionsMachine = fetchedMachines.map((m) => {
                return {
                    value: m.number,
                    label: m.name + " [ " + m.number + " ] "
                }
            });
            var allOptionsMachine = [...optsMachine, ...formatedOptionsMachine];
            setOptionsMachine(allOptionsMachine);
        }
    };

    const handleSaveFiles = async (e) => {
        setErrMsg(null);
        setHasNoTechnique(false);
        if (images.length > 0) {
            if (!configuredWidth || (!autoHeight && !configuredHeight)) {
                setErrMsg("Largeur et hauteur du fichier de sortie ne peut être null.");
            } else if (!activeTechnique) {
                setHasNoTechnique(true);
            } else {

                setProcessNesting(true);
                const formData = new FormData();
                // Ajoute chaque fichier et ses paramètres à l'objet FormData
                for (let i = 0; i < images.length; i++) {
                    const prefix = "PRINT-";
                    const UUID = Utils.formatUUIDWithPrefix(prefix);
                    const fileKey = `image_${i}_`;
                    formData.append(`${fileKey}theFile`, images[i]["theFile"]);
                    formData.append(`${fileKey}originalWidth`, images[i]["originalWidth"]);
                    formData.append(`${fileKey}originalHeight`, images[i]["originalHeight"]);
                    formData.append(`${fileKey}quantity`, images[i]["quantity"]);
                    formData.append(`${fileKey}uuid`, UUID);
                }
                formData.append('mirror', mirror);

                formData.append('configuredWidth', configuredWidth);
                formData.append('configuredHeight', configuredHeight);
                formData.append('REQUEST_ID', Utils.formatUUIDWithPrefix("PRINT-"));
                formData.append('TOTAL_FILES', images.length);
                const res = await imbricateImage(formData);

                if (res.response === "success") {
                    setProcessNesting(false);
                    var binHash = res.binHash;
                    const previewFs = Object.values(binHash);
                    setPreviewFiles(previewFs);
                    console.log("FILES: ", previewFs);
                    imagesRxjs.setImages([], true);
                }
            }

        } else {
            setErrMsg("Aucun fichier n'as été télécharger.");
        }
    }

    const handleEditImage = (image) => {
        var currentAction = "EDIT";
        setAction("EDIT");
        const imageUrl = URL.createObjectURL(image.theFile);
        setActivePreviewImageDatas(imageUrl);
        setActiveFileDatas(image);
        handleOpenDialogAddImage(currentAction);
    }

    const handleDeleteImage = (image) => {
        const userConfirmed = window.confirm("Voulez-vous vraiment supprimer l'image?");
        if (userConfirmed === true) {
            imagesRxjs.removeImage(image);
        }
    }

    const handleUnitPrintFile = async (e, file) => {
        const userConfirmed = window.confirm("Voulez-vous vraiment lancer l'impression?");
        if (userConfirmed === true) {
            var datas = [];
            var dataToSend = {
                printerNumber: file.printerNumber,
                printingTechnique: activeTechnique,
                numberPrint: file.numberPrint,
                fullPathName: file.fullPathName,
                cuttingFullPathName: file.cuttingFullPathName,
            };
            datas = [...datas, dataToSend];
            setPrinting(true);
            var {status} = await traitementFileWithUrl(datas);
            setPrinting(false);
        }
    }

    const handlePrintAllFile = async (e) => {
        const updatedPreviews = previewFiles.map(item => {
            return {
                ...item, // Conserve toutes les autres propriétés de l'objet
                printingTechnique: activeTechnique // Modifie la valeur de test1
            };
        });
        const userConfirmed = window.confirm("Voulez-vous vraiment lancer tous impressions?");
        if (userConfirmed === true) {
            setPrinting(true);
            console.log("Datas: ", updatedPreviews);
            await traitementFileWithUrl(updatedPreviews);
            setPrinting(false);
        }
    }

    const getAllPrintingTechnique = async () => {
        var result = await getPrintingTechnique();
        if (result.status) {
            var fetchedPrintings = result.fetchedPrintings;
            fetchedPrintings = [...printingTechniques, ...fetchedPrintings];
            setPrintingTechniques(fetchedPrintings);
        }
    };

    const handleChangeMirror = (event) => {
        setMirror(event.target.checked);
    };

    const handleChangeTechnique = async (event) => {
        var theValue = event.target.value;
        setPrintingTechniqueFormData({
            ...printingTechniqueFormData,
            [event.target.name]: theValue,
        });

        const filteredMachines = machines.filter(item => item.printingTechnique["@id"] === theValue);

        const foundTechnique = printingTechniques.find(item => item["@id"] === theValue);
        setActiveTechnique(foundTechnique.name);

        const formatedOptionsMachine = filteredMachines.map((m) => {
            return {
                value: m.number,
                label: m.name + " [ " + m.number + " ] "
            }
        });

        const allOptionsMachine = [...aleatoireOpt, ...formatedOptionsMachine];
        setOptionsMachine(allOptionsMachine);
    }

    const handleDrop = async (event) => {
        event.preventDefault()
        event.stopPropagation()
        const element = document.getElementById("customUpload")
        const className = element.className
        element.className = className.replace("drag-over", "")

        const files = await Utils.getAllDroppedFiles(event.dataTransfer.items)
        setDroppedFiles(files)
    }

    const textUpload = "Ajouter des logos",
        textUploadMore = "Ajouter plus";

    const uploadForm = <div className="custom-upload" id="customUpload"
                            style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                            onClick={(e) => {
                                setAction("ADD");
                                handleOpenDialogAddImage("ADD");
                            }}
                            onDrop={handleDrop}
                            onDragOver={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                const element = document.getElementById("customUpload")
                                const className = element.className
                                if (!className.includes("drag-over")) {
                                    element.className = className + " drag-over"
                                }
                            }}
                            onDragLeave={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                const element = document.getElementById("customUpload")
                                const className = element.className
                                element.className = className.replace("drag-over", "")
                            }}
    >
        <label htmlFor="uploadInput">
            <Button
                style={{color: "#fff"}}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<AddIcon/>}
            >
                {images.length === 0 ? textUpload : textUploadMore}
            </Button>
        </label>
    </div>;

    return <>

        <HamburgerMenu/>

        <div style={{marginTop: "40px"}}></div>

        {statusResNesting && <div className="loader-container">
            <div className="spinner"></div>
        </div>}

        <Container>

            {openTaskConfiguration &&
                <TaskConfiguration
                    openTaskConfiguration={openTaskConfiguration}
                    setOpenTaskConfiguration={setOpenTaskConfiguration}
                    files={droppedFiles}
                    onErrorUpload={() => {
                    }}
                    onsubmit={(filesData) => {
                        const files = filesData.map(file => {
                            return {
                                theFile: file.theFile,
                                uuid: Utils.formatUUIDWithPrefix(),
                                quantity: file.numberPrint,
                                originalHeight: +file.height,
                                originalWidth: +file.width,
                                previewUrl: file.previewUrl,
                                machine: "random",
                                copies: 1
                            }
                        })

                        for (const file of files) {
                            imagesRxjs.addImage(file)
                        }

                        setOpenTaskConfiguration(false)
                        setOpenDialogAddImage(false)
                    }}
                />
            }

            <Grid container spacing={2} columns={12}>
                <Grid item xs={12} sm={12}>

                    {images.length === 0 && <>
                        {uploadForm}
                    </>}

                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container direction={"column"} columns={12}>
                        <Grid item sm={12}>
                            <Grid container direction="row" spacing={2}>
                                {images.map((file, index) => {
                                    return <Grid item sm={12} lg={3} key={index}>

                                        <Grid container spacing={1} direction={"row"} alignItems={"center"}
                                              className="bg-light round-4">
                                            <Grid item md={4} className="">
                                                <div className="img-c" style={{
                                                    backgroundImage: "url('/media/images/damier_front.jpg')",
                                                    backgroundSize: "cover"
                                                }}>
                                                    <img
                                                        style={{width: "100%", height: "100%", objectFit: "contain"}}
                                                        alt="Visuel dtf"
                                                        className="img rounded-4"
                                                        src={file.previewUrl ?? URL.createObjectURL(file.theFile)}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item md={8}>
                                                <Stack direction={"row"} justifyContent={"space-between"}
                                                       alignItems={"center"}>
                                                    <div className="" style={{textAlign: "start"}}>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            {file.originalWidth} x {file.originalHeight} cm
                                                        </Typography>
                                                        <Typography variant="body2" gutterBottom>
                                                            Quantité: {file.quantity}
                                                        </Typography>
                                                    </div>
                                                    <div className="text-end ms-auto" style={{textAlign: "end"}}>
                                                        <Stack direction="column" spacing={2}>
                                                            <div>
                                                                <IconButton aria-label="Note"
                                                                            onClick={(e) => handleEditImage(file)}>
                                                                    <ModeEditIcon fontSize={"small"}/>
                                                                </IconButton>
                                                            </div>

                                                            <div>
                                                                <IconButton color={"error"} aria-label="Note"
                                                                            onClick={(e) => handleDeleteImage(file)}>
                                                                    <DeleteIcon fontSize={"small"}/>
                                                                </IconButton>
                                                            </div>
                                                        </Stack>
                                                    </div>
                                                </Stack>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                })
                                }

                                {images.length > 0 && (
                                    <Grid item xs={12} sm={12} lg={3}>
                                        {uploadForm}
                                    </Grid>
                                )
                                }
                            </Grid>
                        </Grid>

                        <Grid item sm={12} md={"auto"}>
                            <Typography variant="h6" gutterBottom>
                                Taille du fichier final souhaitée
                            </Typography>

                            <Grid container direction="column" spacing={1} columns={12} justifyContent={"center"}>
                                <Grid item sm={12} md={"auto"}>
                                    {errMsg && (
                                        <Alert severity="error">{errMsg}</Alert>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} columns={12} justifyContent={"center"}>
                                <Grid item sm={12} md={"auto"}>
                                    <TextField
                                        id="l-image"
                                        label="Largeur max"
                                        helperText="cm"
                                        name={"originalWidth"}
                                        style={{backgroundColor: "#fff", width: "100px"}}
                                        inputProps={{style: {textAlign: 'center', borderRadius: "10px"}}}
                                        value={configuredWidth}
                                        onChange={(e) => {
                                            setConfiguredWidth(e.target.value)
                                            if (!autoHeight) {
                                                if (configuredHeight <= e.target.value) {
                                                    setConfiguredHeight(+e.target.value + 1)
                                                }
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item sm={12} md={"auto"}>
                                    <TextField id="h-image"
                                               label="Hauteur max"
                                               helperText="cm"
                                               name={"originalHeight"}
                                               style={{backgroundColor: "#fff", width: "100px"}}
                                               inputProps={{style: {textAlign: 'center'}}}
                                               value={configuredHeight}
                                               disabled={autoHeight}
                                               onChange={(e) => {
                                                   setConfiguredHeight(e.target.value)
                                                   if (configuredWidth >= e.target.value) {
                                                       setConfiguredWidth(+e.target.value - 1)
                                                   }
                                               }}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={autoHeight}
                                                onChange={() => {
                                                    if(!autoHeight) {
                                                        setConfiguredHeight(0)
                                                    } else {
                                                        setConfiguredHeight(configuredWidth + 1)
                                                    }

                                                    setAutoHeight(!autoHeight)
                                                }}
                                            />}
                                        label="Hauteur auto"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="column" spacing={1} columns={12} justifyContent={"center"}
                                  sx={{mt: 1}}>
                                <Grid item sm={12} md={"auto"}>
                                    <FormControl sx={{minWidth: 300}} size="medium" error={hasNoTechnique}>
                                        <InputLabel id="demo-simple-select-label">
                                            Téchnique d'impression
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="printingTechnique"
                                            value={printingTechniqueFormData.printingTechnique}
                                            label="Téchnique d'impression"
                                            onChange={handleChangeTechnique}
                                        >
                                            {printingTechniques.map(
                                                (item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item["@id"]}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        <FormHelperText>{hasNoTechnique ? "Technique d'impression ne peut être null" : ""}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12} md={"auto"}>

                                    <FormControlLabel required control={
                                        <Checkbox sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>}
                                                      checked={mirror}
                                                      onChange={handleChangeMirror}
                                                      label="Effet mirroir"
                                                      name={"mirror"}
                                    />

                                </Grid>
                            </Grid>

                        </Grid>

                        {statusConfig &&
                            <Grid item sm={12} sx={{marginBottom: "30px", marginTop: "30px",}}>
                                <Box sx={{position: 'relative'}}>
                                    <Button
                                        disabled={processNesting}
                                        variant="contained"
                                        onClick={(e) => handleSaveFiles(e)}>Générer les planches</Button>
                                    {processNesting && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: blue[500],
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <Grid container direction={"column"} columns={12}>

                                <Grid item sm={12}>
                                    <Grid container className="h-100" spacing={2}>
                                        {previewFiles.map((file, index) => {
                                            return <Grid item sm={12} lg={3} key={index}>

                                                <Grid container direction={"row"} alignItems={"center"} spacing={1}
                                                      className="bg-light round-4">

                                                    <Grid item sm={4} className="">
                                                        <div className="img-c" style={{
                                                            backgroundImage: "url('/media/images/damier_front.jpg')",
                                                            backgroundSize: "cover"
                                                        }}>
                                                            <img
                                                                style={{
                                                                    width: '100%',
                                                                    maxWidth: '100%',
                                                                    height: 'auto',
                                                                    display: 'block'
                                                                }}
                                                                alt="Visuel dtf"
                                                                className="img rounded-4"
                                                                src={file.previewFilePath}
                                                            />
                                                        </div>
                                                    </Grid>

                                                    <Grid item sm={12}>
                                                        <div>
                                                            <TextField
                                                                name={"copies"}
                                                                fullWidth
                                                                label="Quantité"
                                                                id="outlined-size-small"
                                                                size="small"
                                                                type={"number"}
                                                                value={file.numberPrint}
                                                                onChange={(e) => {
                                                                    let updatedPreviews = previewFiles.map(item =>
                                                                        item.previewFilePath === file.previewFilePath ? {
                                                                            ...item,
                                                                            numberPrint: e.target.value
                                                                        } : item
                                                                    );
                                                                    setPreviewFiles(updatedPreviews);
                                                                }}
                                                            />
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">
                                                                Imprimante
                                                            </InputLabel>
                                                            <Select
                                                                size={"small"}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                name="machine"
                                                                label="Imprimante"
                                                                value={file.printerNumber}
                                                                onChange={(e) => {
                                                                    let updatedPreviews = previewFiles.map(item =>
                                                                        item.previewFilePath === file.previewFilePath ? {
                                                                            ...item,
                                                                            printerNumber: e.target.value
                                                                        } : item
                                                                    );
                                                                    setPreviewFiles(updatedPreviews);
                                                                }}
                                                            >
                                                                {optionsMachine.map(
                                                                    (item, index) => (
                                                                        <MenuItem
                                                                            key={index}
                                                                            value={item["value"]}
                                                                        >
                                                                            {item.label}
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item sm={12} sx={{textAlign: "start"}}>
                                                        <Button size="small" variant={"contained"}
                                                                onClick={(e) => handleUnitPrintFile(e, file)}>Imprimer</Button>
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>


                        <Grid item sm={12} sx={{marginBottom: "30px", marginTop: "30px"}}>
                            <Button disabled={previewFiles.length === 0} variant="outlined" color="error"
                                    endIcon={<PrintIcon/>} onClick={(e) => handlePrintAllFile(e)}>IMPRIMER TOUS</Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Container>

        {printing &&
            <div className="spinner-overlay">
                <CircularProgress
                    size={40}
                    sx={{
                        color: blue[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            </div>
        }


        <Popup open={openDialogAddImage}
               children={dialogContentAddImage}
               dialogActions={dialogAddImageAction}
               title={"Ajouts images"}
               onDialogClose={handleCloseAddImage}/>
    </>
}
