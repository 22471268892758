import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {Button, TextField} from "@mui/material";
import {Close, Save} from "@mui/icons-material";
import Utils from "../../Utils/Utils";
import Stack from "@mui/material/Stack";
import {useRef} from "react";
import {patchMachine} from "../../Service/MachineService";
import moment from "moment";

export default function MachineNote({machine, onSaveFinish, onClose}) {
    const ref = useRef()

    const handleSave = () => {
        patchMachine(machine.id, {
            note: ref.current.value,
            updatedAt: moment().format()
        }).then(() => {
        })
        onSaveFinish()
    }

    return (<Paper elevation={3} sx={{overflow: "hidden"}}>
        <Box sx={{p: 1, background: "white", width: 300}}>
            <TextField
                label={"Note " + Utils.formatMachineName(machine)}
                multiline
                fullWidth
                rows={4}
                defaultValue={machine.note}
                inputRef={ref}
            />
            <Stack sx={{mt: 1}} direction={"row"} gap={3} alignItems={"center"} justifycontent={"center"}>
                <Button
                    size="small"
                    startIcon={<Save/>}
                    onClick={handleSave}
                >
                    Enregistrer
                </Button>
                <Button
                    size={"small"}
                    color="inherit"
                    startIcon={<Close/>}
                    onClick={onClose}
                >
                    Fermer
                </Button>
            </Stack>
        </Box>
    </Paper>)
}