import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Imprimante from "../Components/Imprimante";
import Home from "../Components/Home";
import Images from "../Components/Images";
import OpenApi from "../Components/OpenApi";
import Assisstant from "../Components/Assisstant";

export const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/imprimantes/config" element={<Imprimante />} />
                <Route path="/images/planches" element={<Images />} />
                <Route path="/asks" element={<OpenApi />} />
                <Route path="/assistants" element={<Assisstant />} />
            </Routes>
        </Router>
    );
};
